import React, { Component } from 'react'

export default class TabSelector extends Component {

	constructor(props) {
		super(props);
		let activeTab = "";
		if (props.tabs && props.tabs.length > 0) activeTab = props.tabs[0];
		if (props.defaultTab) activeTab = props.defaultTab;
		this.state = { activeTab }
	}

	changeTab = (tab, e) => {
		if (tab !== this.state.activeTab) {
			if (this.props.onChange) this.props.onChange(tab, e);
			this.setState({ activeTab: tab })
		}
	}

	render() {
		let { tabs } = this.props;
		if (!tabs || tabs.length === 0) tabs = ["No tabs configured"];
		return (
			<div className={"TabSelector"}>
				{
					tabs.map((TabName, i) => <div key={i} className={"selector" + (this.state.activeTab === TabName ? " active" : "")}
												  onClick={this.changeTab.bind(this, TabName)}>{TabName ? TabName.toString() : TabName}</div>)
				}
			</div>
		)
	}
}
