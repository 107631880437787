import React, {Component} from 'react';

import "./Profile.css";

import * as SocialAPI from "../../helpers/contracts/social";

import AuxLayout from "../../helpers/AuxLayout";
import {withRouter, Link, Route, NavLink} from "react-router-dom";
import {connect} from "react-redux";

import CreateOffer from '../../components/CreateOffer/CreateOffer';
import ActivityFeed from '../../components/ActivityFeed';
import Inventory from '../Inventory/Inventory';
import Button from "../../components/Button/Button";
import Swal from "sweetalert2";
import config from '../../config/config';

class TradeOffer extends Component {
    _mounted = false;
    state = {};
    
    constructor (props) {
        super(props);
        this._lastName = props.match.params.uid;
        this.state = {
            nick: null,
            name: props.match.params.uid,
            avatar: config.media.LOGO,
            loading: false,
            tx_status: "",
        };
    }

    componentDidUpdate () {
        if (this._lastName !== this.props.match.params.uid) {
            this._lastName = this.props.match.params.uid;
            this.fetchProfile(this.props.match.params.uid);
        }
    }

    componentDidMount() {
        this._mounted = true;
        let accountName = this.props.match.params.uid;
        this.fetchProfile(accountName);
    }

    fetchProfile = accountName => {
        if (!this._mounted) return;
        this.setState({
            name: accountName
        })
    }

    componentWillUnmount() {
        this._mounted = false;
    }


    render() {
        return (
            <div className="container" id="profile">
                <div className={"box"}>
                    <div className={"basic"}>
                        <img className={"avatar"} src={this.state.avatar} alt={this.state.name} />
                        <div className={"name"}>
                            <Link to={"/p/" + this.state.name}>
                                <span className={"nick"}>{this.state.nick ? this.state.nick : this.state.name}</span>
                                {
                                    this.state.nick ?
                                        <AuxLayout>
                                            <br />
                                            <small className={"accountName"}><i>({this.state.name})</i></small>
                                        </AuxLayout>
                                    : null
                                }
                            </Link>
                        </div> 
                    </div>
                    {
                        this.state.name !== this.props.userAccount ?
                            <div className={"actions"}>
                                <Link to={"/p/" + this.state.name + "/trade"}><button>Send trade offer</button></Link>
                            </div>
                        : null
                    }
                    <div className={"tx-error"}>{this.state.tx_status}</div>
                </div>
                <div className="profile-links">
                    <NavLink to={"/p/" + this.state.name + "/activity"}>Activity</NavLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink exact to={"/p/" + this.state.name + "/"}>Inventory</NavLink>
                </div>
                <Route exact path={"/p/:uid/"} component={() => <Inventory accountName={this.state.name} noCatalog={true}/>}/>
                <Route path={"/p/:uid/activity"} component={() => <ActivityFeed accountName={this.state.name} />}/>
                <Route path={"/p/:uid/trade"} component={props => <CreateOffer accountName={this.state.name} {...props} />}/>
            </div>
    );
    }
}
const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(withRouter(TradeOffer));