import React, {Component} from 'react';
import Popup from "../Popup/Popup";
import CardSelector from "./CardSelector";

import TabSelector from '../TabSelector';
import PackSelector from './PackSelector';
import config from '../../config/config';

class InventorySelector extends Component {

    state = {
        tab: "Cards"
    }

    toggleSelection = (id) => {
        let newSelected = this.props.selected;
        if (newSelected.includes(id)) {
            newSelected.splice(newSelected.indexOf(id), 1);
        } else {
            newSelected.push(id);
        }
        this.props.updateSelection(newSelected);
    }

    setTab = tab => {
        this.setState({tab})
    }

    render() {
        return (
            <Popup active={this.props.active} close={this.props.close}>
                <div className="trader">
                    <img src={config.media.LOGO} alt="" className="avatar" />
                    <div className="info">
                        <b className="name">{ this.props.uid }</b><br/>
                        <small className="action">{ this.props.selected.length } items selected</small>
                    </div>
                    <button onClick={this.props.close}>Select</button>
                </div>
                <TabSelector defaultTab={this.state.tab} tabs={["Cards", "Packs"]} onChange={this.setTab}/>
                {
                    this.state.tab === "Cards" ?
                    <CardSelector items={this.props.items} toggleSelection={this.toggleSelection} selected={this.props.selected}/>
                    : null
                }
                {
                    this.state.tab === "Packs" ?
                    <CardSelector items={this.props.items} toggleSelection={this.toggleSelection} selected={this.props.selected}/>
                    : null
                }
            </Popup>
        );
    }
}

export default InventorySelector;