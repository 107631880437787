import config from '../config/config';

const packs = [
	{
		series: "Blockchain Heroes",
		edition: "Hero Pack",
		img: `${config.ipfsFiles}/pack5.jpg`,
		cardsInPack: 5,
		token: config.packs.TOKEN_NAMES.five,
		type: config.packs.PACK_TYPES.five,
		author: config.contracts.TOKEN,
		fseries: config.packCollectionName,
	},
	{
		series: "Blockchain Heroes",
		edition: "Titan Pack",
		img: `${config.ipfsFiles}/pack30.jpg`,
		cardsInPack: 30,
		token: config.packs.TOKEN_NAMES.thirty,
		type: config.packs.PACK_TYPES.thirty,
		author: config.contracts.TOKEN,
		fseries: config.packCollectionName,
	}
];
export default JSON.parse(JSON.stringify(packs));