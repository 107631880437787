import React, { Component } from 'react'

export default class Cookies extends Component {

    render() {
        window.location.href = "https://wax.io/privacy";
        return (
            <div className="container" id="cookies" style={this.props.style}>
                Redirecting...
            </div>
        )
    }
}
