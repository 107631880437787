import React, {Component} from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import * as actionTypes from './store/actionTypes';

import './assets/css/index.css';
import LandingPage from "./container/LandingPage/LandingPage";
import Navigation from "./components/Navigation/Navigation";
import Shop from "./container/Shop/Shop";
import Catalog from "./container/Catalog/Catalog";
import Trades from "./container/Trades/Trades";
import Community from "./container/Community/Community";
import SelectTradePartner from "./container/SelectTradePartner/SelectTradePartner";
import TradeOffer from "./container/TradeOffer/TradeOffer";
import AuxLayout from "./helpers/AuxLayout";

import Profile from './container/Profile/Profile';
import Inventory from './container/Inventory/Inventory';
import Unpack from './container/Unpack/Unpack';
import Payment from './container/Payment/Payment';
import FAQ from './container/FAQ/FAQ';
import Terms from './container/Legal/Terms';
import Cookies from './container/Legal/Cookies';

class App extends Component {
    _mounted = false;

    state = {loaded: true};

    withNav = comp => {
        return <AuxLayout>
            <Navigation />
            { comp }
        </AuxLayout>;
    }

    authNeeded = (comp, props={}) => {
        if (this.props.userAccount) {
            return comp;
        } else {
            return <LandingPage {...props} />
        }
    }

    render() {
        if (!this.state.loaded) return <div className={"loader"}><div className={"inner"}/></div>;
        return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/"  component={(props) => <LandingPage {...props} />}/>


                <Route path="/open/:tx"  component={(props) => this.authNeeded(this.withNav(<Unpack {...props} />), props)}/>
                <Route exact path="/payment"  component={(props) => this.withNav(<Payment {...props} />)}/>
                <Route path="/payment/:tx"  component={(props) => this.withNav(<Payment {...props} />)}/>
                <Route path="/gift/"  component={(props) => this.authNeeded(this.withNav(<div className={"container"}>Page pending</div>), props)}/>

                {/*<Route path="/shop"  component={(props) => this.withNav(<Shop {...props} />)}/>*/}
                <Route path="/faq"  component={(props) => this.withNav(<FAQ {...props} />)}/>
                {/*<Route path="/catalog"  component={(props) => this.withNav(<Catalog {...props} />)}/>*/}
                {/*<Route path="/community" component={(props) => this.withNav(<Community {...props}/>)}/>*/}
                <Route path="/inventory"  component={(props) => this.authNeeded(this.withNav(<Inventory {...props} />), props)}/>
                {/*<Route path="/trades"  component={(props) => this.authNeeded(this.withNav(<Trades {...props} />), props)}/>*/}
                {/*<Route exact path="/trade"  component={(props) => this.authNeeded(this.withNav(<SelectTradePartner {...props} />), props)}/>*/}
                {/*<Route exact path="/offer/:id"  component={(props) => this.authNeeded(this.withNav(<TradeOffer {...props} />), props)}/>*/}
                <Route path="/p/:uid"  component={(props) => this.withNav(<Profile {...props} />)}/>


                    {/* Legal */}
                <Route path="/tos"  component={(props) => this.withNav(<Terms {...props} />)}/>
                <Route path="/cookies"  component={(props) => this.withNav(<Cookies {...props} />)}/>
            
                <Route path={"/"} component={() => <Redirect to={"/"}/>} />
            </Switch>
            <Route path="/" render={({location}) => {
                if (typeof window.ga === 'function') {
                    window.ga('set', 'page', location.pathname + location.search);
                    window.ga('send', 'pageview');
                }
                return null;
                }} />
        </BrowserRouter>
        );
    }
}

const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuthUpdate: userAccount => dispatch({type: actionTypes.UPDATE_AUTH_USERACCOUNT, userAccount: userAccount})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);


