import React, { Component } from 'react';

import "./LandingPage.css";

import Globe from "../../assets/images/earth.png";
import Chart from "../../assets/images/chart.png";

import * as actionTypes from "../../store/actionTypes";
import { connect } from "react-redux";
import AuxLayout from "../../helpers/AuxLayout";
import { Link, Redirect } from "react-router-dom";

import Card from "../../components/Card/Card";
import config from '../../config/config';

class LandingPage extends Component {

	state = { redirect: null }

	login = async () => {
		let userAccount = await global.wax.login();
		if (userAccount) {
			this.setState({ redirect: "/shop" })
			this.props.onAuthUpdate(userAccount);
		}
		global.wax.whitelistedContracts = global.whitelistedContracts;
	}

	render() {
		if (this.state.redirect) return <Redirect to={this.state.redirect} />;
		return (
			<div className={"landing"}>
				<div id={"landingpage"}>
					<h1>
						<img className="topps" src={config.media.BRAND} alt="Topps" />
						<br />
						On the <b>WAX BLOCKCHAIN</b> since <b>2020</b>
					</h1>
					<div className="brand">
						<div className="cards">
							<img src={config.getIpfsUrl(config.ipfsFiles + "/common/front/1.jpg")} alt=""
								 className="card" />
							<img src={config.getIpfsUrl(config.ipfsFiles + "/uncommon/front/44.jpg")} alt=""
								 className="card" />
							<img src={config.getIpfsUrl(config.ipfsFiles + "/rare/front/25.jpg")} alt=""
								 className="card" />
							<img src={config.getIpfsUrl(config.ipfsFiles + "/epic/front/24.gif")} alt=""
								 className="card" />
							<img src={config.getIpfsUrl(config.ipfsFiles + "/legendary/front/35.gif")} alt=""
								 className="card" />
							<img src={config.getIpfsUrl(config.ipfsFiles + "/mythic/front/34.gif")} alt=""
								 className="card" />
							<img src={config.getIpfsUrl(config.ipfsFiles + "/golden/front/10.gif")} alt=""
								 className="card" />
						</div>
						<img src={config.media.BIG_LOGO} alt="" className="brand-logo" />
					</div>
					<div className="actions">
						{
							!this.props.userAccount ?
								<AuxLayout>
									<small><i>Sign in using your WAX Cloud Wallet</i></small>
									<br />
									<button onClick={this.login}>Sign in</button>
									<button onClick={this.login} className="full">Create Account</button>
									<div className="continue">
										<Link to={"/shop"}>continue as guest</Link>
									</div>
								</AuxLayout>
								:
								<div className="continue">
									<Link to={"/inventory"}>
										<button className="full">Trade Cards & Packs Now</button>
									</Link>
									<a href={"https://heroes.atomichub.io"}>
										<button className="">Buy on Secondary Market</button>
									</a>
								</div>
						}
					</div>
				</div>
				<div id={"more"}>

					<div className={"card-details"}>
						<p style={{ color: 'black' }}>
							<b>
								Blockchain Heroes is a collectible digital trading card set featuring original characters inspired by blockchain and
								cryptocurrency personalities! This set of NFTs (non-fungible tokens) is available exclusively on the WAX blockchain.
							</b>
						</p>

						<p>
							Long ago, the land was free, and all was decentralized. But the power-mongering forces of darkness, The Centralizers,
							lulled the population under their control.
						</p>
						<p>
							The people suffered under their evil regime for eons. Then, the enigmatic figure known only as Genesis discovered a
							powerful new technology – Blockchain – and unleashed it upon the world with the hope that freedom would once again settle
							upon the land.
						</p>
						<p>

							The populace began to see the promise of seizing back their liberty and privacy. But just as the future became bright,
							Genesis mysteriously vanished!
						</p>

						<p>
							Now devoid of a leader to wield the powers of Blockchain against the evil Centralizers, brave citizens have taken it upon
							themselves to rise to the challenge and harness Blockchain to free the world. This ragtag association of warriors,
							renegades and specialists is known only as the Blockchain Heroes.
						</p>

					</div>

					<br />
					<div className={"blockchain"}>
						<h2 className={"title"}>
							The Hero Cards <small><i style={{ fontWeight: "300" }}>(and their rarities)</i></small>
						</h2>
						<p style={{ fontWeight: "400", color: "a7a7a7" }}>Learn more about the rarities <Link
							style={{ color: "black", borderBottom: "1px dotted black" }}
							to={"/faq#rarities"}>here</Link></p>
						<p style={{ fontWeight: "400", color: "#a7a7a7" }}><small>Hover over or click on a card</small></p>
						<div className={"cards-rarity"}>

							{
								[
									{
										rarityName: "Common",
										cardid: 1,
										path: "/common/front/1.jpg",
										pathBack: "/common/back/1.jpg"
									},
									{
										rarityName: "Uncommon",
										cardid: 44,
										path: "/uncommon/front/44.jpg",
										pathBack: "/uncommon/back/44.jpg"
									},
									{
										rarityName: "Rare",
										cardid: 25,
										path: "/rare/front/25.jpg",
										pathBack: "/rare/back/25.jpg"
									},
									{
										rarityName: "Epic",
										cardid: 24,
										path: "/epic/front/24.gif",
										pathBack: "/epic/back/24.jpg"
									},
									{
										rarityName: "Legendary",
										cardid: 35,
										path: "/legendary/front/35.gif",
										pathBack: "/legendary/back/35.jpg"
									},
									{
										rarityName: "Mythic",
										cardid: 34,
										path: "/mythic/front/34.gif",
										pathBack: "/mythic/back/34.jpg"
									},
									{
										rarityName: "Golden",
										cardid: 10,
										path: "/golden/front/10.gif",
										pathBack: "/golden/back/10.jpg"
									},
									{
										rarityName: "Sticker",
										cardid: 6,
										path: "/sticker/front/6.png"
									},
									{
										rarityName: "Class",
										path: "/class/front/2.png",
										pathBack: "/class/back/2.jpg",
									}
								].map((x, i) => {
									return <div key={i} className={"card-rarity"}>
										<Card src={config.getIpfsUrl(config.ipfsFiles + x.path)}
											  back={x.pathBack ? config.getIpfsUrl(config.ipfsFiles + x.pathBack) : null}
											  noFlip={x.pathBack ? false : true}
											  cardid={x.cardid}
										/>
										<div className={"name"}><b>{x.rarityName}</b></div>
									</div>
								})
							}
						</div>
					</div>
					<footer>
						<div>
							&copy; InfoMedia, Inc. and The Bad Crypto Podcast
						</div>
						<div>
							<Link to={"/cookies#cookies"}>Cookies</Link>
							<Link to={"/tos#tos"}>Terms of Service</Link>
						</div>
					</footer>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		userAccount: state.userAccount
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onAuthUpdate: userAccount => dispatch({ type: actionTypes.UPDATE_AUTH_USERACCOUNT, userAccount: userAccount })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);