import React, { Component } from 'react'

import './FAQ.css';

import FAQHelper from "../../helpers/faq"
import AuxLayout from '../../helpers/AuxLayout';

export default class FAQ extends Component {


	renderFAQ() {
		return FAQHelper.map(({ id, question, answer }, i) => {
			question = typeof question === "string" ? <span>{question}</span> : question;
			answer = typeof answer === "string" ? <span>{answer}</span> : answer;
			return (
				<AuxLayout key={i}>
					<section id={id} className={"question"}>
						{question}
					</section>
					<div className={"answer"}>
						{answer}
					</div>
				</AuxLayout>
			);
		})
	}

	render() {
		return (
			<div className="container" id="faq" style={this.props.style}>
				<h2 className={"title"}>Frequently Asked Questions: Blockchain Heroes Cards on WAX</h2>
				{this.renderFAQ()}
			</div>
		)
	}
}
