import React, { Component } from 'react';

import Swal from 'sweetalert2'
import "./TradeOffer.css";

import * as API from "../../helpers/API";

import AuxLayout from "../../helpers/AuxLayout";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Packs from "../../helpers/packs";
import Pagination from '../../components/Pagination';
import ItemView from '../../components/ItemView';

import Button from "../../components/Button/Button";

import AtomicAssetsAPI from '../../helpers/AtomicAssetsAPI';
import replaceIpfsHash from '../../helpers/ipfsDebugger';

class TradeOffer extends Component {
    _mounted = false;
    state = {
        offer: undefined,
        redirect: null,

        loading: false
    };

    componentDidMount() {
        this._mounted = true;
        // GetTradeID
        this.fetchTrade(this.props.match.params.id);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchTrade = tid => {
        // Load Trade
        AtomicAssetsAPI.getOffer(tid).then(offer => {
            this.setState({ offer })
        })
    }

    acceptOffer = async () => {
        this.setState({ loading: true });
        try {

            await API.acceptOffer(this.state.offer.offer_id);
            await Swal.fire({
                title: 'Offer Accepted!',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            this.setState({ redirect: "/trades" })
        } catch (err) {
            console.error(err);
            await Swal.fire({
                title: 'Accepting failed!',
                text: err.toString(),
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        this.setState({ loading: false });
    }

    denyOffer = async tid => {
        this.setState({ loading: true });
        try {
            await API.denyOffer(this.state.offer.offer_id);
            await Swal.fire({
                title: 'Offer Declined!',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            this.setState({ redirect: "/trades" })
        } catch (err) {
            console.error(err);
            await Swal.fire({
                title: 'Denying failed!',
                text: err.toString(),
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        this.setState({ loading: false });
    }

    cancelOffer = async () => {
        this.setState({ loading: true });
        try {
            await API.cancelOffer(this.state.offer.offer_id)
            await Swal.fire({
                title: 'Offer Cancelled!',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            this.setState({ redirect: "/trades" })
        } catch (err) {
            console.error(err);
            await Swal.fire({
                title: 'Cancellation failed!',
                text: err.toString(),
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        this.setState({ loading: true });
    }

    render() {
        if (this.state.redirect) return <Redirect to={this.state.redirect} />;
        if (this.state.offer === null) return <Redirect to={"/trades"} />;
        if (!this.state.offer) return "Loading trade offer";
        let accountName = global.wax.userAccount;
        let offer = this.state.offer;

        let items_to_send = offer.sender_assets.map((asset, i) => {
            return <ItemView key={i} name={asset.name} image={replaceIpfsHash(asset.data.img)} />
        });
        let items_to_receive = offer.recipient_assets.map((asset, i) => {
            return <ItemView key={i} name={asset.name} image={replaceIpfsHash(asset.data.img)} />
        })
        return (
            <div className="container" id="tradeoffer">
                <div className="box">
                    <div className="trader">
                        <div className="info">
                            <b className="name">{this.state.offer.sender_name} {this.state.offer.sender_name === accountName ?
                                <small><i>You</i></small> : null}</b><br />
                            <small
                                className="action">sends {offer.sender_assets.length} item{(offer.sender_assets.length) !== 1 ? "s" : ""}</small>
                        </div>
                    </div>
                    <Pagination wrapper={items => <div className="items">{items}</div>}
                        items={items_to_send} />

                </div>
                <div className="box">
                    <div className="trader">
                        <div className="info">
                            <Link to={"/p/" + this.state.offer.recipient_name}><b
                                className="name">{this.state.offer.recipient_name} {this.state.offer.recipient_name === accountName ?
                                    <small><i>You</i></small> : null}</b></Link><br />
                            <small
                                className="action">sends {offer.recipient_assets.length} item{(offer.recipient_assets.length) > 1 ? "s" : ""}</small>
                        </div>
                    </div>
                    <Pagination wrapper={items => <div className="items">{items}</div>}
                        items={items_to_receive} />
                </div>
                <div className="actions">
                    {
                        this.state.offer.recipient_name === accountName ? (
                            <AuxLayout>
                                <Button loading={this.state.loading ? 1 : 0}
                                    onClick={() => this.acceptOffer()}>Accept
                                    Offer</Button>
                                <Button loading={this.state.loading ? 1 : 0} onClick={this.denyOffer}>Deny
                                    Offer</Button>
                            </AuxLayout>
                        ) : null
                    }
                    {
                        this.state.offer.sender_name === accountName ?
                            (<Button loading={this.state.loading ? 1 : 0} onClick={this.cancelOffer}>Cancel
                                Offer</Button>)
                            : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(withRouter(TradeOffer));