import config from "../../config/config";

const IPFS_HASH = config.ipfsFiles;

const names = [
	"Genesis",
	"Metallik",
	"Litespeed",
	"Coin Commando",
	"Trilogy",
	"Magic Man",
	"Master Control",
	"Stampede",
	"Brightstroke",
	"Mr. Wick",
	"CypherFunk",
	"Felonious",
	"The Oracle",
	"Super Stomp",
	"X-Change",
	"Great Gecko",
	"Courage",
	"Renegade",
	"Lady Lightning",
	"Silver Scientist",
	"Wyld Wrangler",
	"The Emissary",
	"Data Avenger",
	"The Wizard",
	"Overseer",
	"Forecaster",
	"Professor Axiom",
	"Maximum",
	"The Chairman",
	"The Senator",
	"Mz Stability",
	"Dynamic Defiant",
	"Bitcoin Bro",
	"Soothe n' Sayer",
	"GingerGirl",
	"Jester",
	"The Creatrix",
	"Scrivener",
	"Rowdy Rook",
	"Rocketman",
	"The Prophet",
	"Rock Fierce",
	"The Crusader",
	"Supernova",
	"Ethermage",
	"Repeat",
	"Executive Powers",
	"Everlasting",
	"Captain Crypto",
	"MegaMine",
]

const classNames = [
	"Boosters",
	"Champions",
	"Defenders",
	"Celestials",
	"Creators"
]


//needs to return an array with objects looking like this:
/*

{
   "name":"Corroded Carl",
   "images":{
      "front":"QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/sketch/19a.gif",
      "mp4":"QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/sketch/19a.mp4",
      "webm":"QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/sketch/19a.webm",
      "back":"QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/back/19.jpg"
   },
   "cardid":19,
   "rarity":"Sketch"
}

 */

function getCard(name, cardid, rarity, cardidOffset = 0) {
	return {
		name,
		cardid: cardid + cardidOffset,
		images: {
			front: `https://cloudflare-ipfs.com/ipfs/${IPFS_HASH}/${rarity}/front/${cardid}.${config.fileTypes[rarity].front}`,
			back: `https://cloudflare-ipfs.com/ipfs/${IPFS_HASH}/${rarity}/back/${cardid}.${config.fileTypes[rarity].back}`,
		},
		rarity
	}
}

function getSticker(name, cardid, rarity, cardidOffset = 0) {
	return {
		name,
		cardid: cardid + cardidOffset,
		images: {
			front: `https://cloudflare-ipfs.com/ipfs/${IPFS_HASH}/${rarity}/${cardid}.${config.fileTypes[rarity].front}`,
			back: ''
		},
		rarity
	}
}

export default [
	...names.map((e, i) => getCard(e, i + 1, "common")),
	...names.map((e, i) => getCard(e, i + 1, "uncommon")),
	...names.map((e, i) => getCard(e, i + 1, "rare")),
	...names.map((e, i) => getCard(e, i + 1, "epic")),
	...names.map((e, i) => getCard(e, i + 1, "legendary")),
	...names.map((e, i) => getCard(e, i + 1, "mythic")),
	...names.map((e, i) => getCard(e, i + 1, "sticker")),
	...names.map((e, i) => getCard(e, i + 1, "secret")),
	...classNames.map((e, i) => getCard(e, i + 1, "class")),
];