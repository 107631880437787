import React from 'react';
import ReactDOM from 'react-dom';
import * as waxjs from "@waxio/waxjs/dist";
import App from './App';

import "./config/style.scss";
/* Redux */
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Reducer from './store/reducer';
import * as CompabilityAPI from './helpers/Compability';
import EnvHandler from './helpers/EnvHandler';


const build_version = "v1.0.0.0-live"
let reducerClass = new Reducer();

global.monthNames = ["January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
]

if (!process.env.REACT_APP_MOONPAY_PUBLIC_KEY) {
	//alert("MOONPAY PUBLIC KEY MISSING");
	//throw "MOONPAY PUBLIC KEY MISSING";
}

if (EnvHandler.isDevMode()) console.log("[Dev Mode] Enabled")


/* waxjs */
const wax = new waxjs.WaxJS({
	rpcEndpoint: "https://api.waxnet.io",
	tryAutoLogin: false,
});
global.whitelistedContracts =
	[
		{ "domain": "bcheroes.com", "contract": "heroes", "recipients": [] },
		{ "domain": "bcheroes.com", "contract": "unbox.heroes", "recipients": [] },
		{ "domain": "bcheroes.com", "contract": "atomicassets", "recipients": [] },
	];

wax.whitelistedContracts = global.whitelistedContracts;

global.wax = wax;

console.log("build_version", build_version);
console.log(CompabilityAPI.getDeviceInformation());

async function start() {
	try {
		let available = await wax.isAutoLoginAvailable();
		if (available) {
			let userAccount = await wax.login();
			reducerClass.updateInitialStore({ userAccount: userAccount });
		}
	} catch (e) {
	}

	const store = createStore(reducerClass.reducer);
	ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
}

start();
