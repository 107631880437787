import BigNumber from 'big-number';
import {numericFromName} from '../../helpers/utils'

import * as API from '../API';

const contractName = API.SOCIAL_CONTRACT;

function dec2hex(str) { // .toString(16) only works up to 2^53
	let dec = str.toString().split(''), sum = [], hex = [], i, s
	while (dec.length) {
		s = 1 * dec.shift()
		for (i = 0; s || i < sum.length; i++) {
			s += (sum[i] || 0) * 10
			sum[i] = s % 16
			s = (s - sum[i]) / 16
		}
	}
	while (sum.length) {
		hex.push(sum.pop().toString(16))
	}
	return hex.join('')
}


export const findWalletByName = search => {
	return new Promise((resolve, reject) => {
		if (!search) return reject();

		let missing = "";
		for (let i = 0; i < (12 - search.length); i++) {
			missing += "z";
		}
		fetch("https://api.waxnet.io/v1/chain/get_table_by_scope",
			{
				"body": JSON.stringify({
					"code": "eosio",
					"table": "userres",
					"lower_bound": search,
					"upper_bound": search + missing,
					"limit": 50
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(res => {
				resolve(res);
			});
	});
}
