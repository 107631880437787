import React from 'react';
import { Link } from 'react-router-dom';

const faq = [

	{
		question: "What is the Blockchain Heroes x WAX partnership about?",
		answer: <span>
			Collectors can buy, sell, gift, and trade digital collectibles of the Blockchain Heroes, a set of parody cards inspired by blockchain and cryptocurrency personalities - all on the WAX Blockchain. Owners of these collectibles can:

			<ul>
				<li>Trade them instantly, with anyone, anywhere in the world - for free!</li>
				<li>Trade with confidence that every collectible is certified authentic, unique, and can never be altered</li>
				<li>View a collectible's full ownership records and trading history</li>
				<li>Showcase their inventory on social media</li>
				<li>Search wishlists of other traders and propose trades</li>
			</ul>
		</span>
	},
	{
		id: "rarities",
		question: "What are the different types of Blockchain Heroes cards that are on WAX?",
		answer: <span>
			<p>The Blockchain Heroes card series features 50 original heroes with descriptive text and stats on the back. Cards come in 1 of 8 rarities:</p>
			<ul>
				<li> <b>Base</b> Common cards.</li>
				<li><b>Uncommon Comic</b> - Uncommon cards provide a nod to the comic book genre, where many people first discovered super heroes.</li>
				<li><b>Rare DaVinci</b> - Rare cards are inspired by Leonardo DaVinci’s drawings.</li>
				<li><b>Epic Sketch</b> - Epic cards are the first rarity that includes animation. A salute to the Garbage Pail Kids Sketch card that paved the way, this card combines color with a hint of DaVinci.</li>
				<li><b>Legendary Shockwave</b> – Legendary cards are bombastic, with an animated explosion of color.</li>
				<li><b>Mythic Stars</b> – Mythic cards are regal and elegant, reverberating with glowing stars.</li>
				<li><b>Golden Fury</b> -  Golden Fury cards are so rare they are not found in packs. Rather, two of each hero will be randomly airdropped to 100 wallets which participated in the initial pack sale.</li>
				<li><b>Electric Genesis</b> – Electric Genesis is the rarest card in the set, with only ONE copy found in the entire set. This 1/1 card is guaranteed to drop from a Hero pack.</li>
			</ul>
		</span>

	},

	{
		question: "What is inside the Blockchain Heroes card packs?",
		answer: <span>

		When you buy a Blockchain Heroes card pack, you can open the pack to reveal what’s inside.
Each Hero pack contains at least five cards with these odds:

			<ul>
<li>100% chance at a Uncommon Card</li>
				<li>50% chance at a Rare Card</li>
				<li>15% chance at a Epic Card</li>
				<li>6% chance at a Legendary Card</li>
				<li>1% chance at a Mythic Card</li>
				<li>20% chance at a bonus Sticker Card</li>
				<li>10% chance at a bonus Class Card</li>
				<li>Chance to get 1/1 Electric Genesis</li>
			</ul>

Each Titan pack contains at least thirty cards with these odds:

			<ul>
				<li>5 Uncommon Cards</li>
				<li>3 Rare Cards</li>
				<li>1 Epic Card</li>
				<li>60% chance at a Legendary Card</li>
				<li>5%% chance at a Mythic Card</li>
				<li>100% chance at a bonus Sticker Card</li>
				<li>50% chance at a bonus Class Card</li>
			</ul>
		</span>
	},
	{
		id: "howtogetcards",
		question: "How do I buy Blockchain Heroes cards and packs?",
		answer: <span>
			<p>
			To purchase card packs during the PRIMARY sale: <br />
			Log in to BCHeroes.com with your WAX Cloud Wallet account (create an account if you don’t have one already). Visit the Shop, select your card packs, and complete the purchase.
			</p>
			<p>
To purchase card packs or individual cards on SECONDARY markets (after the primary sale has ended): <br />
				visit a secondary marketplace (like <a
				href={"https://heroes.atomichub.io"}>atomichub.io</a> or <a href={"https://waxstash.com"}>waxstash.com</a>) and log in with your WAX Cloud Wallet account.
				Secondary markets only accept WAX (WAXP) Tokens as payment, you can read more about them here.
				</p>
		</span>
	},
	{
		question: "What can collectors do with their Blockchain Heroes cards on WAX?",
		answer: <span>Collectors can sell their cards on a marketplace, trade them with others, showcase their inventory on social media, or gift their cards to a friend.</span>
	},
	{
		question: "How do I trade Blockchain Heroes cards to someone else on WAX?",
		answer: <span>
			You can trade or even gift your Blockchain Heroes cards and packs to someone else by visiting bcheroes.com/trades and clicking
			"Send Offer." Then select the community member that you wish to trade with. Select the item you wish to send from your inventory
			and if applicable, choose the items from your trading partner’s inventory that you wish to receive in return. Then click
			"Send Offer." Your pending offers appear on the Trade page.
		</span>
	},
	{
		question: "Where can I get support?",
		answer: <span>Have questions about Blockchain Heroes on WAX? Visit <a href="https://t.me/bcheroes" target="_blank">t.me/bcheroes</a></span>
	},
	{
		question: "Is there a preferred browser that I should use when purchasing cards?",
		answer: <span>
    Yes, we recommend using Firefox or Google Chrome.
    </span>
	},
	{
		question: "How does this partnership benefit buyers, traders, and collectors of digital trading cards?",
		answer: <span>
    By interacting with digital Blockchain Heroes collectibles on the WAX Blockchain, anyone can see the details of the cards information and trading history. This enables everyone involved to make informed purchases, and trades, with anyone else in the world - instantly.<br />
<br />
The blockchain records every aspect of every item’s transaction and stores it forever. These blockchain records can’t be changed by anyone, and can be seen by everyone. Therefore, since the blockchain removes all uncertainty, there’s no need to blindly trust another trader.<br />
<br />

Data for Blockchain Heroes cards on the WAX Blockchain includes:
Detailed specifications for each card including the date it was created, its rarity, images of the card, proof of its authenticity, and more. With this information, collectors know exactly which cards they are trading - no guesswork, no need to trust or verify other traders.<br />
<br />

Comprehensive ownership records that show who traded the item and when. Since everyone can see its full ownership history, no one can ever fake ownership.
Complete trade history
    </span>
	},
	{
		question: "Why did Blockchain Heroes choose the WAX Blockchain?",
		answer: <span>
    Blockchain Heroes chose WAX because the WAX Blockchain is one of the safest and most convenient platforms to create, buy, sell, and trade virtual items to anyone, anywhere in the world. WAX has facilitated the trade of more than $100 million digital items since 2017.
    </span>
	},
	{
		question: "What is WAX?",
		answer: <span>
    WAX is a safe and convenient way to create, buy, sell, and trade virtual items - to anyone, anywhere in the world. Hundreds of millions of people around the globe already trade digital and physical items. But the problems that plague the digital goods and trading community have stunted its growth far beneath its full potential. WAX is creating a full suite of blockchain-based tools that allows anyone to trade digital or even physical items instantly and securely, to anyone, anywhere. Participants of the Worldwide Asset eXchange gain access to a global community of collectors and traders, buyers and sellers, creators and gamers, merchants, dApp creators, and game developers. Learn more at https://wax.io
    </span>
	},
	{
		question: "About Blockchain Heroes",
		answer: <span>
    Blockchain Heroes is the brainchild of Joel Comm and Travis Wright, co-hosts of the popular blockchain show, <a href="https://badcryptopodcast.com/" target="_blank">The Bad Crypto Podcast</a>. With millions of downloads since the show’s debut in 2017, the pair have interviewed some of the biggest names in blockchain.  Their objectives for this product are to release the best set of digital collectibles on blockchain ever created and to bring together a community of collectors who are equally passionate about the project.
    </span>
	},
	{
		question: "About the Team",
		answer: <span>

<a href="https://joelcomm.com" target="_blank"><b style={{color: "black"}}>Joel Comm</b></a>
<br />
Joel Comm is a New York Times bestselling author, blockchain enthusiast, professional keynote speaker, social media marketing strategist, live video expert, technologist, brand influencer, futurist and eternal 12-year-old.<br />
			<br />
<br />
<a href="https://traviswright.com" target="_blank"><b style={{color: "black"}}>Travis Wright</b></a>
<br />
Travis Wright is a top marketing technologist, author, keynote speaker, blockchain advisor, tech journalist, and podcast host. He is the former global digital and social strategist at Symantec for the Norton brand.
			<br />

<br />
<b>Zach Comm</b><br />
Zach Comm has built and managed user-facing systems for a variety of enterprises, including Fortune 500 components distributor Arrow Electronics. He's applied his systems expertise alongside his love of fantasy worlds to bring life to the Blockchain Heroes as the team's Creative Director.
			<br />

<br />
<b>Jharek Wright</b><br />
Jharek Wright is a 2020 high school graduate who took specialized multimedia courses at Summit Tech. With Blockchain Heroes, he was the Photoshop production artist executing the effects on over 65% of cards in the collection.
			<br />


<b>The Artists</b><br /><br />

A big thanks to our talented artists who helped realize the vision of our heroes. <br />

Each is listed with the Hero Class they designed and a link to their business profile.<br />

<ul>
	{
		[
			{ name: "Julia Dovzhuk", class: "Creator Class", url: "https://www.upwork.com/fl/artisjul", },
			{ name: "Albert Chauw", class: "Champion Class", url: "https://www.upwork.com/freelancers/~01a1d8b4f9ff514d76", },
			{ name: "Valerika Che", class: "Defender Class", url: "https://www.upwork.com/freelancers/~01d604c092d06279d4", },
			{ name: "Christian Mirra", class: "Booster Class", url: "https://www.upwork.com/freelancers/~017387f49cbb9cc756", },
			{ name: "Ruben Orellana", class: "Celestial Class", url: "https://www.upwork.com/freelancers/~01bdb8898848a01e4b", }
		].map(x => {
		return <li>{x.name} – <a href={x.url} target={"_blank"}>{ x.class }</a></li>
		})
	}
</ul>
    </span>
	}

];

export default faq
