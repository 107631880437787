import config from '../config/config.js';

export default function replaceIpfsHash(input) {
	if (input) {
		//input = input.replace('QmUUQ99hmEaB8JJPVsg6jkqoxHQAWcSyPWKM5FFJTqwAAL', 'QmYkMDkB1d8ToHNHnFwpeESF3Npfid671NrfbPKiKG8e25');
		//input = input.replace('Qm12345', config.ipfsFiles);
		input = input.replace("Q123123123123", config.ipfsFiles)
		//input = input.replace("pack5.jpg", "pack5.png")
	}
	return input;
}
