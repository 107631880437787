import * as actionTypes from './actionTypes';

export default class Reducer {
    initialStore;
    constructor() {
        this.initialStore = {userAccount: null};
    }

    updateInitialStore = store => {
        this.initialStore = {
            ...this.initialStore,
            ...store
        };
    }

    reducer = (store=this.initialStore, action) => {
        if (action.type === actionTypes.UPDATE_AUTH_USERACCOUNT) {
            return {
                ...store,
                userAccount: action.userAccount
            }
        }
        return store;
    }
}