import React, {Component} from 'react';

import ItemView from '../ItemView';
import Pagination from '../Pagination';
import replaceIpfsHash from '../../helpers/ipfsDebugger';

class CardSelector extends Component {
    render() {
        let items = [];
        
        if (this.props.extra) items.push(this.props.extra)
        items = 
        [
            ...items,
            ...this.props.items.map((item, i) => {
                let name = item.name, image = item.data.img;

                if (image && !image.includes("http")) image = "https://cloudflare-ipfs.com/ipfs/" + image;
                
                image = replaceIpfsHash(image);
                return (
                    <ItemView key={i} className={this.props.selected && this.props.selected.includes(item) ? "selected " : ""} name={name} image={image} onClick={this.props.toggleSelection ? () => this.props.toggleSelection(item) : null} removalAction={this.props.removalAction ? () => this.props.removalAction(item) : null} />
                )
            })
        ]
        return this.props.noPagination ? items : <Pagination wrapper={items => <div className="items">{items}</div>} items={items} />;
    }
}

export default CardSelector;