import { contracts } from "../API";
import config from '../../config/config';
import EnvHandler from "../EnvHandler";

const products = [
	{
		"name": "Series 1",
		"edition": "Hero Pack",
		"image": `${config.ipfsFiles}/pack5.png`,
		"content": [
			"100% chance at a Uncommon Card",
			"50% chance at a Rare Card",
			"15% chance at a Epic Card",
			"6% chance at a Legendary Card",
			"1% chance at a Mythic Card",
			"20% chance at a bonus Sticker Card",
			"10% chance at a bonus Class Card",
			"Chance to get 1/1 Electric Genesis"
		],
		"cardCount": 5,
		"price": EnvHandler.isDevMode() ? 1 : 100, // Price in WAX $4.99
		"price_us": 499,
		"collection": config.collection_name,
		"templateId": config.templates.hero,
		"minPurchase": 1,
		"maxPurchase": 25,
		"date": "Aug 8, 2020"
	},
	{
		"name": "Series 1",
		"edition": "Titan Pack",
		"image": `${config.ipfsFiles}/pack30.png`,
		"content": [
			"5 Uncommon Cards",
			"3 Rare Cards",
			"1 Epic Card",
			"60% chance at a Legendary Card",
			"5% chance at a Mythic Card",
			"100% chance at a bonus Sticker Card",
			"50% chance at a bonus Class Card"
		],
		"cardCount": 30,
		"price": EnvHandler.isDevMode() ? 50000 : 500, // Price in WAX $24.99
		"price_us": 2499,
		"collection": config.collection_name,
		"templateId": config.templates.titan,
		"minPurchase": 1,
		"maxPurchase": 10,
		"date": "Aug 8, 2020"
	},
];

export default {
	getProducts: () => {
		return new Promise(resolve => resolve(products))
	},
	getProductByToken: token => {
		let p = products.filter(product => product.token == token);
		if (p && p.length) {
			return (p[0])
		} else {
			return (null)
		}
	}
};