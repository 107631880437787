import React, { Component } from "react";
import Card from "../Card/Card";
import gsap from 'gsap';

import './Reveal.css'
import { Redirect } from "react-router-dom";

export default class Reveal extends Component {

	_mounted = false;

	container = React.createRef();
	carousel = React.createRef();

	addX = 0;
	mouseX = 0;
	mouseY = 0;
	mouseZ = -3200;
	radius = null;
	ticker = null;
	state = {
		w: null,
		item: null,
		itemLength: null,
		rY: null,

		items: [],

		usedRadius: 90,
		redirect: false
	}

	constructor(props) {
		super(props);

		this.cardRefs = [];
		this.cardObjectsRefs = [];
	}

	componentWillUnmount() {
		clearInterval(this.looper);
	}

	componentDidMount() {
		let items = this.props.items;
		if (!items) {
			items = [];
			for (let i = 0; i < 25; i++) {
				items.push({})
			}
		}

		//const rY = this.state.usedRadius / items.length;
		//this.radius = Math.round((900) / Math.tan(Math.PI / items.length));
		const rY = 7.5;
		this.radius = 3359;
		this.radiusPerCard = this.radius / 12;

		this.setState({
			items,
			rY,
		});

		window.addEventListener("mousemove", this.onMouseMove, false);

		//w = $(window);
		//container = $('#contentContainer');
		//carousel = $('#carouselContainer');
		//item = $('.carouselItem');
		//itemLength = $('.carouselItem').length;


		// set container 3d props
		//console.log('container', this.container);
		gsap.set(this.container.current, { perspective: 600 })
		gsap.set(this.carousel.current, { z: -(this.radius) })


		const initialize = () => {
			if (this.cardRefs.length) {
				for (let i = 0; i < this.cardRefs.length; i++) {
					const item = this.cardRefs[i];
					const block = item.children[0];
					//console.log('item', item);
					//console.log('block', block);

					gsap.set(item, {
						rotationY: this.state.rY * i,
						z: this.radius,
						transformOrigin: "50% 50% " + -this.radius + "px"
					});

					this.animateIn(item, block)
				}
			} else {
				setTimeout(() => {
					initialize();
				}, 5)
			}
		}
		initialize();

		// create carousel item props

		// set mouse x and y props and looper ticker
		this.ticker = setInterval(this.looper, 1000 / 60);
	}

	onMouseMove = (event) => {
		//console.log('window.innerwidth', window.innerWidth, event.pageX);
		this.mouseX = event.pageX / window.innerWidth;
		//this.mouseX = -(-(window.innerWidth * .5) + event.pageX) * .0025;
		//this.mouseY = -(-(window.innerHeight * .5) + event.pageY) * .01;
		//this.mouseZ = -(this.radius) - (Math.abs(-(window.innerHeight * .5) + event.pageY) - 200);
		//console.log('mousez,', this.mouseZ);
	}

	looper = () => {
		//this.addX += this.mouseX;
		//console.log('addx', this.addX);
		//gsap.to(carousel, 1, { rotationY: addX, rotationX: mouseY, ease: Quint.easeOut })
		//console.log('carousel', this.carousel.current);
		if (this.carousel.current) {
			gsap.to(this.carousel.current, 1, { rotationY: this.addX, rotationX: this.mouseY })
			gsap.set(this.carousel.current, { z: this.mouseZ })
		}
	}

	getRandomInt = ($n) => {
		return Math.floor((Math.random() * $n) + 1);
	}

	animateIn = (item, block) => {
		const $nrX = 360 * this.getRandomInt(2);
		const $nrY = 360 * this.getRandomInt(2);

		const $nx = -(2000) + this.getRandomInt(4000)
		const $ny = -(2000) + this.getRandomInt(4000)
		const $nz = -4000 + this.getRandomInt(4000)

		const $s = .5;
		const $d = .5;

		gsap.set(item, { autoAlpha: 1, delay: $d })
		gsap.set(block, { z: $nz, rotationY: $nrY, rotationX: $nrX, x: $nx, y: $ny, autoAlpha: 0 })
		gsap.to(block, $s, { delay: $d, rotationY: 0, rotationX: 0, z: 0 })

		gsap.to(block, $s - .5, { delay: $d, x: 0, y: 0, autoAlpha: 1 })
	}

	setTarget = (e, i) => {
		//this is hacky and should be changed...
		if (this.lastSetTarget && this.lastSetTarget + 10 > new Date().getTime())
			return;
		this.lastSetTarget = new Date().getTime();

		//console.log('e', i);
		//console.log(this.state.items.length);

		let newAddX = -(this.state.usedRadius / 12) * i;
		/*
		if (this.currentActiveItem !== i || newAddX !== this.addX) {
			if (this.mouseX < 0.4) {
				newAddX -= radiusPerItem / 2;
			} else if (this.mouseX > 0.6) {
				newAddX += radiusPerItem / 2;
			}
		}
		 */
		this.addX = newAddX;

		if (i !== this.currentActiveItem) {
			this.currentActiveItem = i;
			setTimeout(() => {
				if (this.cardObjectsRefs[i] && this.cardObjectsRefs[i].initTilt)
					this.cardObjectsRefs[i].initTilt();
			}, 500);
		}
		//console.log('new addx: ' + this.addX);
	}

	next = (showBackside, i) => {
		if (showBackside) {
			return true;
		} else if (i === this.currentActiveItem) {
			if (i + 1 < this.state.items.length) {
				this.setTarget(null, i + 1)
			} else {
				console.log('REDIRECT');
				this.setState({
					redirect: true
				})
			}
		}
	}

	render() {
		if (this.state.redirect) return <Redirect to={"/inventory?tab=cards"} />

		return (
			<div id="contentContainer" ref={this.container} className="trans3d">
				<section id="carouselContainer" ref={this.carousel} className="trans3d">
					{
						this.state.items.map((nft, i) => {
							return (
								<figure
									className="carouselItem trans3d"
									onClick={e => this.setTarget(e, i)}
									key={i}
									ref={
										card => this.cardRefs[i] = card
									}
								>
									<div className="carouselItemInner trans3d">
										<Card
											ref={
												card => this.cardObjectsRefs[i] = card
											}
											startBackside={true}
											portrait={true}
											onFlip={showBackside => this.next(showBackside, i)}
											src={`https://cloudflare-ipfs.com/ipfs/${nft.img}`}
											back={`https://cloudflare-ipfs.com/ipfs/QmYkMDkB1d8ToHNHnFwpeESF3Npfid671NrfbPKiKG8e25/back/1.jpg`} />
									</div>
								</figure>
							);
						})
					}
					<figure
						className="carouselItem trans3d"
						key={'inventory'}
					>
						<div className="carouselItemInner trans3d">
							<button>Inventory</button>
						</div>
					</figure>
				</section>
			</div>

		);
	}
}
