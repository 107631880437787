import React, { Component } from 'react'

import TrashIcon from "../assets/icons/trash.svg";

export default class ItemView extends Component {
    render() {
        let {className, name, image } = this.props;
        if (image && !image.includes("http")) image = "https://cloudflare-ipfs.com/ipfs/" + image;
        return (
            <div className={"ItemView" + (className ? " " + className : "")} onClick={this.props.onClick}>
                <img src={image} title={name} alt={name} />
                { this.props.removalAction ? <img className={"trash"} src={TrashIcon} alt={""} onClick={this.props.removalAction} /> : null}
            </div>
        )
    }
}
