import React, { Component } from 'react'

export default class Terms extends Component {

	render() {
		return (
			<div className="container" id="terms" style={this.props.style}>
				<h1>Terms of Use</h1>
				<p>Thank you for visiting the "bcheroes.com" website (the “Site”). WAX (“WAX”, “we”, “us” or “our”) provides its Site, products, and
					services (collectively, “the Service”) to you, subject to the following terms of use (“Terms” or “this Agreement”). If you do not
					agree to these Terms, you must cease your use of this site, and you may not use any of the Products & Services.</p>
				<p>We may update these Terms from time to time. Please ensure you check the Terms each time you access or use this site. If we make
					material changes to the Terms, we will post the revised Terms and the revised effective date at the bottom of these Terms. Your
					continued use of any of the Service after the date of any such changes become effective constitutes your acceptance of the new
					Terms.</p>
				<p>Your personal information will be used in accordance with our <a href="https://wax.io/privacy" rel="noopener noreferrer"
																					target="_blank">Privacy Policy</a>.
				</p>
				<p>No one at WAX is authorized to modify the Terms with you or otherwise enter into an agreement with you that conflicts with these
					Terms, except by means of a written agreement signed by an authorized agent of WAX or as described above in the Terms, and any
					other purported modifications or alterations or conflicting terms will be null and void.</p>
				<p>PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL
					RIGHTS, REMEDIES, AND OBLIGATIONS.</p>
				<h2>Use of the Site</h2>
				<p>This Site, and the information which it contains, is the property of WAX and its affiliates and licensors, and is protected from
					unauthorized copying and dissemination by United States copyright law, trademark law, international conventions and other
					intellectual property laws. WAX is a tradename of Exposition Park Holdings SEZC (“EPH”). All WAX product names and logos are
					trademarks or registered trademarks of EPH. All other company and product names and logos are trademarks or registered trademarks
					of their respective owners in certain countries. All credit card and ACH payments on WAX are serviced by Widney LLC.</p>
				<p>The information contained on this Site is provided on an “as is” basis, without any knowledge as to your specific circumstances.
					The Site may become unavailable due to maintenance or malfunction of computer equipment or other reasons, and there may also be
					delays, omissions, or inaccuracies in information contained on the Site. The information on the Site does not constitute the
					rendering of legal, accounting, tax, or other such professional advice.</p>
				<p>No part of the Site may be reproduced or transmitted in any form, by any means, electronic or mechanical, including photocopying
					and recording, except that WAX authorizes you to view, copy, download, and print WAX documents available on this Site, provided
					that you use the documents solely for noncommercial, informational purposes, that you not modify the documents, and that you not
					remove copyright, trademark, and other proprietary notices.</p>
				<p>Nothing contained on this Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use
					this Site or any documents displayed on this Site, through the use of framing or otherwise, except as expressly permitted by these
					Terms or with the prior written permission of WAX.</p>
				<p>Any information, including but not limited to remarks, suggestions, ideas, graphics, or other submissions communicated to WAX
					through this Site is the exclusive property of WAX. WAX is entitled to use any information submitted for any purpose, without
					restriction (except as stated in our Privacy Policy) or compensation to the person sending the submission. The user acknowledges
					the originality of any submission communicated to WAX and accepts responsibility for its accuracy, appropriateness, and
					legality.</p>
				<p>Information that WAX publishes on this Site may contain references to products or services that are not available or approved by
					the appropriate regulatory authorities in your country. Such references do not imply that WAX intends to announce or make
					available such products or services to the general public or in your country.</p>
				<h2>Use of the Products and Services</h2>
				<p>WAX provides the services, by which persons can order packs of nonfungible tokens (“Packs”) for personal use, where the payment is
					processed by a third party. When opening packs, the odds of finding each type of item are as displayed on the pack purchase
					screen. Packs are available only to customers in certain locations. You may not purchase or use any of these items if you are not
					in an approved location. With respect to any violation of these terms, you agree to hold harmless, indemnify and release WAX from
					any liability in accordance with these Terms.</p>
				<p>You must be 18 or older to buy or sell items on the Site, regardless of any consent from your parent or guardian to use the
					Service.</p>
				<p>Due to restrictions imposed by WAX’s third-party service providers, WAX reserves the right to refuse service under the terms and
					conditions of this Agreement when so required by its third-party service providers, to the fullest extent allowed by applicable
					law.</p>
				<p>WAX further reserves the right, in its sole and absolute discretion, to refuse service under the terms and conditions of this
					Agreement if WAX has reason to believe that your funds are subject to any dispute as to the ownership of such funds or the means
					by which you acquired such funds.</p>
				<p>You need a supported Web browser to access the Site and to use the Service. You acknowledge and agree that WAX may cease to support
					a given Web browser and that your continuous use of the Site and Service will require you to download a supported Web browser. You
					also acknowledge and agree that the performance of the Site and Service is dependent on the performance of your computer equipment
					and your Internet connection.</p>
				<p>You are solely responsible for all Packs and items you obtain. WAX shall have no liability to you or anyone else for the use,
					handling, transfer, loss, or other disposition of your Packs and items.</p>
				<h2>Your Responsibilities</h2>
				<p>You agree that you are responsible for all activities that occur on your account or through the use of your password by yourself or
					by other persons. If you believe that a third party has access your password, use the password regeneration feature of the Site as
					soon as possible to obtain a new password. In all circumstances, you agree not to permit any third party to use or access the
					Service or Site through your password.</p>
				<p>You may not use any form of unauthorized third-party software, scripts, or the like including without limitation: automation
					software (bots), mods, scrapers, “userscripts,” “addons,” “extensions”, hacks, etc., to modify, automate, and/or change the
					purchasing process in any way and/or for the purpose of data collection.</p>
				<p>As a condition to your use of the Site and Service, you agree not to: (a) impersonate or misrepresent your affiliation with any
					person or entity; (b) access, tamper with, or use any non-public areas of the Site and Service or WAX’ computer systems; (c)
					attempt to probe, scan, or test the vulnerability of the Site, Service, or any related system or network, or breach any security
					or authentication measures used in connection with the Site, Service and such systems and networks; (d) attempt to decipher,
					decompile, disassemble, reverse engineer, or otherwise investigate any of the software or components used to provide the Site or
					Service; (e) harm or threaten to harm other users in any way or interfere with, or attempt to interfere with, the access of any
					user, host, or network, including without limitation by sending a virus, overloading, flooding, spamming, or mail-bombing the Site
					or Service, or otherwise; (f) provide payment information belonging to a third party; (g) use the Site or Service in an abusive
					way contrary to its intended use, to WAX’s policies and instructions, to any obligation you owe to any third party, or to any
					applicable law; (h) systematically retrieve data or other content from the Service to create or compile, directly or indirectly,
					in single or multiple downloads, a collection, compilation, database, directory, or the like, whether by manual methods, through
					the use of bots, crawlers, or spiders, or otherwise; (i) make use of the Site or Service in a manner contrary to the terms and
					conditions under which third parties provide facilities and technology necessary for the operation of the Site and Service; (j)
					infringe third-party intellectual property rights when using or accessing the Site or Service; and (k) make use of, promote, link
					to, or provide access to materials deemed by WAX, at its sole and unfettered discretion, to be offensive or cause harm to WAX’s
					reputation, including without limitation illegal content and pornographic content and other content deemed offensive or injurious
					to WAX and/or the Service (such as Warez sites, IRC bots and bittorent sites).</p>
				<p>Your online conduct and interaction with other users of the Site and Service should be guided by common sense and basic etiquette.
					WAX may terminate your access to the Site or Service at any time (in WAX’ sole and unfettered discretion), for any conduct or
					activity that WAX believes, in its sole and unfettered discretion, is illegal, violates the terms of this Agreement, or otherwise
					negatively affects the enjoyment of the Site and Service by other users. You acknowledge that WAX is not required to provide you
					notice before terminating your access to the Service or the Site, but it may choose to do so.</p>
				<p>You represent, warrant, covenant, and agree that (a) you are not located in, and you are not a national or resident of, any country
					to which the United States, United Kingdom, European Union, Australia, or Canada has embargoed goods and/or services of the same
					type as the Services (“Restricted Countries”), including without limitation Cuba, Iran, North Korea, Sudan, or Syria; and (b) you
					are not a person or entity, or owned by, under the control of, or affiliated with, a person or entity (i) that appears on the U.S.
					Office of Foreign Assets Control’s Specially Designated Nationals List, Foreign Sanctions Evaders List, or Palestinian Legislative
					Council List; (ii) that appears on the U.S. Department of State’s Terrorist Exclusion List; (iii) that is subject to sanctions in
					any other country; or (iv) that is engaged in the design, development, or production of nuclear, biological, or chemical weapons,
					missiles, or unmanned aerial vehicles. You are not allowed to use the Service if we identify your IP address or address of
					residence or use of the Service as originating from any such country.</p>
				<p>You further represent, warrant, covenant, and agree that (a) you will not use, trade, sell, or otherwise dispose of any item from
					this Site or the Service in any manner that is contrary to, or in violation of, any and all applicable national, federal, state,
					provincial, and local laws, statutes, regulations, rules, codes, ordinances, orders, or directives (“Applicable Laws”); (b) you
					have the full right and authority to use the Site and the Service and to be bound by these Terms; (c) and you will at all times
					comply with Applicable Laws; and (d) the Site and the Service are strictly for amusement and entertainment purposes, the Site and
					the Service does not offer real money or an opportunity to win real money or prizes, and no money or thing of value can be won
					through the Site or the Service.</p>
				<h2>Investigation and Enforcement</h2>
				<p>WAX will have the right to investigate and prosecute violations of any term or provision of this Agreement or your use of the Site
					and Service, including without limitation possible infringement of any intellectual property rights and possible security
					breaches, to the fullest extent of the law. WAX may involve and cooperate with law enforcement authorities in prosecuting users
					who violate this Agreement or other applicable law.</p>
				<p>You acknowledge that, although WAX has no obligation to monitor your access to or use of the Site and Service, it has the right to
					do so for the purpose of operating the Site and Service, to ensure your compliance with this Agreement, or to comply with
					applicable law or the order or requirement of a court, administrative agency, or other governmental body. You further acknowledge
					that during the pendency of any investigation, WAX may revoke your access to the Site or the Service.</p>
				<p>BY ACCEPTING THESE TERMS, YOU WAIVE ALL RIGHTS NOT SPECIFICALLY SET FORTH HEREIN, AND AGREE TO HOLD WAX HARMLESS FROM ANY CLAIMS
					RESULTING FROM ANY ACTION TAKEN BY WAX DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
					INVESTIGATIONS BY EITHER WAX OR LAW ENFORCEMENT AUTHORITIES.</p>
				<h2>EULAs and Third Parties</h2>
				<p>WAX and/or entities that sell products on this Site are primarily responsible for fulfillment of orders, warranty, maintenance,
					technical, and product support services for those products. Purchase of certain products may also require shoppers to agree to one
					or more End-User License Agreements (or “EULAs”) that include additional terms set by the product supplier, rather than by WAX or
					a payment facilitator. You will be bound by any EULA to which you agree.</p>
				<p>WAX makes no express representations or warranties and hereby disclaims any implied warranty regarding any third-party site or
					services. In particular, WAX makes no representation or warranty and disclaims any implied warranty that any service or
					subscription offered via third-party vendors will not change or not be suspended or terminated.</p>
				<h2>Payments and Fees</h2>
				<p>The payment facilitator is primarily responsible for facilitating payment for your purchase. When you use any third-party payment
					facilitator (“payment facilitator”) to make a purchase on this Site, responsibility over your purchase will first be transferred
					to the payment facilitator before it is delivered to you. The payment facilitator assumes primary responsibility, with WAX’s
					assistance, for payment and payment-related customer support. The terms between the payment facilitator and customers who utilize
					the services offered by the payment facilitator are governed by separate agreements and are not subject to the Terms on this
					Site.</p>
				<p>For purchases made through a payment facilitator, the privacy policy of the payment facilitator shall apply to all payments and
					should be reviewed before making any purchase. You are responsible for any fees, taxes or other costs associated with the purchase
					and delivery of your items resulting from charges imposed by your relationship with payment services providers or the duties and
					taxes imposed by your local customs officials or other regulatory body. Any separate charges or obligations you incur in your
					dealings with other third-party service providers are your responsibility.</p>
				<p>WAX accepts no liability and specifically disclaims any implied warranty to complete any transaction that cannot be cleared by its
					payment facilitator, whether because there are not sufficient funds available on your credit card, issues related to identity or
					localization or otherwise. WAX reserves its right to verify and approve any and all transactions made by you when using the
					Service to ensure that they comply with this Agreement and the terms imposed to WAX by third-party service providers. You
					expressly acknowledge and agree that such verifications may require you to provide WAX with additional personal information in
					order to verify and confirm your identity and to perform verifications aimed to deter fraud and misuse of the Service, in
					compliance with WAX’s Privacy Policy.</p>
				<p>All monies stipulated in these Terms and in the Service are expressed in USD and may include additional applicable taxes. Items
					offered on the Site are available for delivery worldwide, except for Restricted Countries. Purchased digital goods are delivered
					instantly.</p>
				<p>You agree not to initiate any claim and dispute procedure with third parties regarding payment and receipt of virtual items. Any
					refunds for items purchased following your instructions shall be at WAX’s sole, unfettered, and exclusive discretion, and provided
					only on an exceptional basis.</p>
				<h2>DISPUTE RESOLUTION - AGREEMENT TO ARBITRATE</h2>
				<p>Dispute Resolution by Binding Arbitration; Jury Trial Waiver; Class Action Waiver.</p>
				<p>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</p>
				<p>If WAX is unable to resolve your concerns and a dispute remains between you and WAX, this Section explains how the parties have
					agreed to, and shall, resolve it.</p>
				<p>You and WAX agree to make reasonable, good faith efforts to informally resolve any dispute before you initiate formal dispute
					resolution. You agree to send WAX a written notice that describes the nature and basis of the claim or dispute and sets forth the
					relief sought. Written notice to WAX must be sent via postal mail to Floor 4, Willow House, Cricket Square, Grand Cayman,
					KY1-9010, Cayman Islands (“Notice Address”).</p>
				<p>FORMAL DISPUTE RESOLUTION: If WAX and you do not resolve the claim within sixty (60) calendar days after the Notice is received,
					then your options for formal dispute resolution depend upon your country of residence. This Section does not prevent you from
					bringing your dispute to the attention of any federal, state, or local government agencies that can, if the law allows, seek
					relief from us for you.</p>
				<p>FOR RESIDENTS OF THE UNITED STATES & OTHER JURISDICTIONS THAT ENFORCE BINDING ARBITRATION: YOU AND WAX AGREE THAT ANY DISPUTE,
					CLAIM, OR CONTROVERSY BETWEEN YOU AND WAX ARISING IN CONNECTION WITH OR RELATING IN ANY WAY TO THESE TERMS OR TO YOUR RELATIONSHIP
					WITH WAX AS A USER OF THE SERVICE (WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION, OR ANY OTHER LEGAL THEORY,
					AND WHETHER THE CLAIMS ARISE DURING OR AFTER THE TERMINATION OF THE SERVICE) WILL BE DETERMINED BY MANDATORY BINDING INDIVIDUAL
					(NOT CLASS, REPRESENTATIVE, OR CONSOLIDATED ACTION) ARBITRATION. YOU AND WAX FURTHER AGREE THAT THE ARBITRATOR SHALL HAVE THE
					EXCLUSIVE POWER TO RULE ON HIS OR HER OWN JURISDICTION, INCLUDING ANY OBJECTIONS WITH RESPECT TO THE EXISTENCE, SCOPE OR VALIDITY
					OF THE ARBITRATION AGREEMENT OR TO THE ARBITRABILITY OF ANY CLAIM OR COUNTERCLAIM.</p>
				<p>Arbitration is a proceeding before a neutral arbitrator, instead of before a judge or jury. Arbitration is less formal than a
					lawsuit in court, and provides more limited discovery. It follows different rules than court proceedings, and is subject to very
					limited review by courts. The arbitrator will issue a written decision and provide a statement of reasons if requested by either
					party. YOU UNDERSTAND THAT YOU ARE GIVING UP THE RIGHT TO SUE IN COURT AND TO HAVE A TRIAL BEFORE A JUDGE OR JURY.</p>
				<p>YOU AND WAX AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
					CLASS MEMBER (OR IN A REPRESENTATIVE OR CONSOLIDATED ACTION) IN ANY PURPORTED CLASS OR, REPRESENTATIVE, OR CONSOLIDATED ACTION.
					Unless both you and WAX agree, no arbitrator or judge may consolidate more than one person’s claims or otherwise preside over any
					form of a representative or class proceeding, and the arbitrator specifically does not have the power to alter this. The
					arbitrator may award injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to
					provide relief warranted by that party’s individual claim. If a court decides that applicable law precludes enforcement of any of
					this Section’s limitations as to a particular claim for relief, then that claim (and only that claim) must be severed from the
					arbitration and may be brought in court.</p>
				<p>Either you or we may start arbitration proceedings. Any arbitration between you and WAX will be administered at the International
					Chamber of Commerce (“ICC”) International Court of Arbitration under the Rules of Arbitration of the ICC then in force (the “ICC
					Rules”), as modified by this Arbitration Agreement, or, if ICC no longer exists or is unable to participate, such other
					arbitration forum selected by WAX. The language to be used in the arbitral proceeding shall be English. For more information on
					the ICC, the Rules and Procedures, or the process for filing an arbitration claim, you may call the ICC in Paris, France at +33
					(0) 1 49 53 28 28 or visit the ICC website at https://iccwbo.org.</p>
				<p>Unless WAX expressly agrees in writing to the contrary, the parties shall keep confidential all awards and orders in any
					arbitration pursuant to this section, as well as all materials in the arbitral proceedings created for the purpose of the
					arbitration and all other documents produced by another party in the arbitral proceedings not otherwise in the public domain;
					provided that the foregoing shall not prevent either party from making any disclosure of such to the extent that disclosure is
					required of a Party by a legal duty, to protect or to pursue a legal right, or to enforce or challenge an award in legal
					proceedings before the appropriate court or other judicial authority. You and WAX agree that the US Federal Arbitration Act
					applies and governs the interpretation and enforcement of this provision, to the extent applicable.</p>
				<p>Absent a contrary decision of the arbitrator or otherwise required by applicable law, the parties agree that the seat and venue of
					the arbitration is the Cayman Islands. The language of the arbitration will be English. The arbitration will be conducted before
					one commercial arbitrator from the International Chamber of Commerce (“ICC”) with substantial experience in resolving commercial
					contract disputes. As modified by these Terms, and unless otherwise agreed upon by the parties in writing, the arbitration will be
					governed by the ICC’s Arbitration Rules including its Expedited Procedure Provisions (collectively, the “Rules and Procedures”).
					The ICC Emergency Arbitrator Provisions shall not apply. </p>
				<p>The dispute will be resolved by the submission of documents without a hearing, unless a hearing is requested by a party or deemed
					to be necessary by the arbitrator, in which case, a party may elect to participate telephonically. The arbitrator shall make a
					decision in writing, and shall provide a statement of reasons if requested by either party. The arbitrator must follow applicable
					law, and any award may be challenged if the arbitrator fails to do so. You and WAX may litigate in court to compel arbitration, to
					stay proceeding pending arbitration, or to confirm, modify, vacate or enter judgment on the award entered by the arbitrator.</p>
				<p>Nothing in this Section removes or limits WAX’ liability for fraud, fraudulent misrepresentation, death or personal injury caused
					by its negligence, and, if required by applicable law, gross negligence. Additionally, notwithstanding this agreement to
					arbitrate, claims for infringement or misappropriation of the other party’s patent, copyright, trademark, trade secret or other
					intellectual property rights shall not be subject to arbitration under this Section.</p>
				<p>You or WAX may seek emergency equitable relief before a court located in the Cayman Islands in order to maintain the status quo
					pending arbitration and you agree to submit to the exclusive personal jurisdiction of the courts located within the Cayman Islands
					for such purpose. A request for interim measures shall not be deemed to be a waiver of the right to arbitrate.</p>
				<p>FOR RESIDENTS OF THE EUROPEAN UNION & OTHER JURISDICTIONS THAT DO NOT ENFORCE THE BINDING ARBITRATION ABOVE: Any non-arbitrable
					disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of the
					Cayman Islands. </p>
				<p>This Section will survive termination of your account and these Terms as well as any voluntary payment of any debt in full by you
					or any bankruptcy by you or WAX. With the exception of any provision of this Section prohibiting arbitration on a class or
					collective basis, if any part of this arbitration provision is deemed to be invalid, unenforceable, or illegal, or otherwise
					conflicts with the Rules and Procedures, then the balance of this arbitration provision will remain in effect and will be
					construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting part was not contained herein. If,
					however, any provision of this Section prohibiting arbitration on a class or collective basis is found to be invalid,
					unenforceable, or illegal, then the entirety of this arbitration provision will be null and void, and neither you nor WAX will be
					entitled to arbitration.</p>
				<p>YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATING TO THE
					SERVICE OR THESE TERMS MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR IT WILL BE FOREVER
					BARRED.</p>
				<h2>User Disputes</h2>
				<p>You agree that you are solely responsible for your interactions with any other user in connection with the Service and WAX will
					have no liability or responsibility with respect thereto. We reserve the right, but have no obligation, to become involved in any
					way with disputes between you and any other user of the Service.</p>
				<h2>General</h2>
				<p>These Terms will be governed by the laws, restrictions, regulations and rules of the Cayman Islands without regard to its conflict
					of law provisions. With respect to any disputes or claims that do not fall within the agreement to arbitrate, set out above, you
					and WAX agree to submit to the personal and exclusive jurisdiction of the Cayman Islands. The failure of WAX to exercise or
					enforce any right or provision of these Terms will not constitute a waiver of such right or provision. If any provision of these
					Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor
					to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full
					force and effect. A printed version of these Terms and of any notice given in electronic form will be admissible in judicial or
					administrative proceedings based upon or relating to these Terms to the same extent and subject to the same conditions as other
					business documents and records originally generated and maintained in printed form. You may not assign these Terms without the
					prior written consent of WAX, but WAX may assign or transfer these Terms, in whole or in part, without restriction. The section
					titles in these Terms are for convenience only and have no legal or contractual effect. Notices to you may be made via either
					email or regular mail or other electronic service (as described in Communication Services above).</p>
				<p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and WAX as a result of these
					Terms or your use of any of the Service. WAX’s performance of these Terms is subject to existing laws and legal process, and
					nothing contained in these Terms is in derogation of WAX’s right to comply with governmental, court and law enforcement requests
					or requirements relating to your use of the Service or information provided to or gathered by WAX with respect to such use.</p>
				<p>Unless otherwise specified herein, these Terms constitute the entire agreement between the user and WAX with respect to the Service
					and these Terms supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between
					the user and WAX with respect to the Service, except for any Terms of Token Sale into which you have entered with WAX. A printed
					version of these Terms and of any notice given in electronic form shall be admissible in judicial or administrative proceedings
					based upon or relating to these Terms to the same extent and subject to the same conditions as other business documents and
					records originally generated and maintained in printed form. It is the express wish to the parties that these Terms and all
					related documents be written in English.</p>
				<h2>Termination</h2>
				<p>WAX reserves the right to temporarily or permanently discontinue the Site or Service at any time. WAX will deploy commercially
					reasonable efforts to notify you of such discontinuation, for example, by posting updates and notices on various social media.</p>
				<h2>Proprietary Rights</h2>
				<p>All right, title, and interest in and to the Service are and will remain the exclusive property of WAX and, to the extent
					applicable, third parties providing facilities and technology for its operation. The Site and Service are protected by copyright,
					trademark, and other domestic and foreign laws concerning intellectual property. Except as expressly permitted in this Agreement,
					you may not reproduce, modify, or create derivative works based upon, distribute, sell, transfer, publicly display, publicly
					perform, transmit, or otherwise use the Site or Service. You acknowledge and agree that, notwithstanding anything to the contrary
					in these Terms, you shall have no ownership or other property interest in the Site or to the Services.</p>
				<p>WAX grants you the personal, non-transferable, non-exclusive, revocable, and limited right to access and use the Site and Service
					solely for your own personal amusement and entertainment purposes as an individual consumer. This right to access confers no title
					or ownership in the Site or Service. Except as otherwise provided by this Agreement, you may not use the Site or Service for any
					purpose other than your permitted access to the Site and Service, to make personal, non-commercial use of the Site and Service.
					Except as otherwise permitted under this Agreement, or under applicable law notwithstanding these restrictions, you may not, in
					whole or in part, copy, photocopy, reproduce, publish, distribute, translate, reverse engineer, derive source code from, modify,
					disassemble, decompile, create derivative works based on, or remove any proprietary notices or labels from the Site or Service or
					any software accessed via the Site without the prior written consent of WAX.</p>
				<p>You are entitled to use the Service and Site solely for your own personal amusement and entertainment purposes only, but you are
					not entitled to: (i) sell, grant a security interest in or transfer reproductions of the Service to other parties in any way, nor
					to rent, lease, or license the Service to others without the prior written consent of WAX, except to the extent expressly
					permitted elsewhere in this Agreement; (ii) host or provide matchmaking services for the Service or emulate or redirect the
					communication protocols used by WAX in any network feature of the Service, through protocol emulation, tunneling, modifying, or
					adding components to the Service, use of a utility program or any other techniques now known or hereafter developed, for any
					purpose including, but not limited to network play over the Internet, network play utilizing commercial or non-commercial gaming
					networks or as part of content aggregation networks, websites, or services, without the prior written consent of WAX; or (iii)
					exploit the Service or any of its parts for any commercial purpose, except as expressly permitted elsewhere in this Agreement.</p>
				<h2>Disclaimer of Warranty and Limitation of Liability</h2>
				<p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, WAX MAKES NO WARRANTY OR REPRESENTATION AND SPECIFICALLY DISCLAIMS ANY IMPLIED
					WARRANTIES REGARDING THE SITE AND SERVICE, INCLUDING THAT THE SITE AND SERVICE WILL MEET YOUR REQUIREMENTS OR WILL WORK IN
					COMBINATION WITH ANY HARDWARE OR SOFTWARE PROVIDED BY THIRD PARTIES, THAT THE SITE AND SERVICE WILL BE UNINTERRUPTED, WITHOUT
					PROBLEMS OR ERROR FREE, OR THAT ALL ERRORS IN THE SITE AND SERVICE WILL BE CORRECTED. WAX PROVIDES THE SITE AND SERVICE “AS IS,”
					“AS AVAILABLE,” AND “WITH ALL FAULTS.”</p>
				<p>YOU USE THE SITE AND SERVICE AT YOUR OWN RISK, AND THE SITE AND SERVICE MAY BE TERMINATED AT ANY TIME BY WAX OR THIRD PARTIES
					PROVIDING FACILITIES AND TECHNOLOGY FOR ITS OPERATION. WAX DOES NOT ENCOURAGE, CONDONE, OR PROMOTE THE COMMERCIAL USE OF THE SITE
					AND SERVICE.</p>
				<p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, WAX’ WARRANTIES AND REMEDIES (IF ANY) EXPRESSLY SET FORTH HEREIN ARE EXCLUSIVE AND
					ARE IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, EITHER IN FACT OR BY OPERATION OF LAW, STATUTE, CUSTOM, ORAL OR WRITTEN
					STATEMENTS, OR OTHERWISE, INCLUDING, BUT NOT LIMITED, TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, AVAILABILITY, PERFORMANCE,
					COMPATIBILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, CORRESPONDENCE WITH DESCRIPTION AND NON-INFRINGEMENT, ALL
					OF WHICH ARE EXPRESSLY DISCLAIMED.</p>
				<p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT SHALL WAX AND ITS SUPPLIERS OR LICENSORS HAVE ANY LIABILITY, WHETHER
					BASED IN CONTRACT, DELICT OR TORT (INCLUDING NEGLIGENCE), OR STRICT LIABILITY, FOR INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL,
					OR PUNITIVE DAMAGES OF ANY KIND, OR FOR LOSS OF REVENUE OR PROFITS, LOSS OF BUSINESS OR GOODWILL, LOSS OR CORRUPTION OF, OR
					UNAUTHORIZED ACCESS TO, OR DISCLOSURE OF INFORMATION OR DATA, OR LOSS OF ANY UNREALIZED SAVINGS OR EXPECTED BENEFIT, OR OTHER
					FINANCIAL LOSS ARISING OUT OF OR IN CONNECTION WITH THE USE, PERFORMANCE, FAILURE, OR INTERRUPTION OF THE SITE AND SERVICE,
					WHETHER FORESEEABLE OR NOT, AND EVEN IF WAX HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WAX HEREBY DISCLAIMS ALL SUCH
					DAMAGES TO THE MAXIMUM EXTENT ALLOWED BY LAW. IN THE EVENT THAT WAX IS FOUND LIABLE TO PAY YOU ANY DAMAGES, INCLUDING
					REIMBURSEMENT OF EXPENSES LIKE ATTORNEYS’ FEES, WAX’ TOTAL CUMULATIVE LIABILITY TO YOU UNDER THIS AGREEMENT SHALL NOT EXCEED $100.
					THE ABOVE LIMITATIONS OF LIABILITY WILL NOT BE AFFECTED IF ANY REMEDY PROVIDED HEREIN SHALL FAIL ITS ESSENTIAL PURPOSE.</p>
				<h2>Indemnification and Release</h2>
				<p>You agree to defend, indemnify, and hold harmless WAX, its affiliates, and its and their officers, directors, employees, and
					agents, from and against any Claim (as defined below) arising out of or in any way connected with your access to or use of the
					Site or the Service. “Claim” includes, without limitation, any past, present, or future actions, causes of action, demands,
					assertions of rights, costs, demands, expenses, injuries, judgments, losses suits, damages, and attorneys’ fees and other costs of
					defense, of any kind or nature whatsoever, whether in tort, contract, pursuant to statute, at law or equity or otherwise, whether
					direct or indirect, contingent or absolute, and whether known, foreseeable, liquidated, anticipated or suspected.</p>
				<p>To the maximum extent permitted by law, and except as expressly provided herein, you, on behalf of yourself and on behalf of your
					past, present, and future successors, predecessors, assigns, heirs, affiliates, agents, insurers, representatives, and any
					individual, person, organization, entity, corporation, or representative who or which acquires or succeeds to your rights and
					responsibilities, hereby absolutely, unconditionally, and irrevocably forever release and discharge WAX, together with all of WAX’
					and its affiliates’ past, present, and future successors, predecessors, and assigns, and all of its and their shareholders, parent
					companies, subsidiaries, affiliates, insurers, representatives, officers, directors, and employees, from any and all Claims
					arising out of or in any way related to your use or inability to use the Site or the Service. </p>
				<h2>Applicable Law; Jurisdiction</h2>
				<p>These Terms and all Disputes (as defined below) are governed by and will be construed and enforced in accordance with the internal
					laws of the Cayman Islands without regard to choice-of-law rules. “Dispute” is a controversy, disagreement, or claim between the
					parties with respect to, arising out of, or relating to these Terms in any manner whatsoever, whether in contract or tort, or
					whether legal or equitable. Any non-arbitrable Disputes shall be subject to the exclusive jurisdiction of the courts of the Cayman
					Islands, which shall be the exclusive and mandatory venue and forum for any and all Disputes. You expressly, knowingly, and
					voluntarily consent to the personal jurisdiction and to the exclusive jurisdiction of the Cayman Islands and their courts, and you
					waive any objection based on lack of personal jurisdiction, improper venue, or forum non conveniens.</p>
				<h4>Notice for California Users</h4>
				<p>Under California Civil Code Section 1789.3, users of the Service from California USA are entitled to the following specific
					consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of
					Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at
					(916) 445-1254 or (800) 952-5210. You may contact us at WAX at Floor 4, Willow House, Cricket Square, Grand Cayman KY1-9010,
					Cayman Islands.</p>
				<p>If you are a California resident, you may have certain rights with respect to your personal information including the following
					rights:</p>
				<ul>
					<li>the right to know, by way of our Privacy Policy and any specific inquiries you direct to us, where personal information we
						have about you comes from, what we use that information for, whether it is being disclosed or sold, and to whom it is being
						disclosed or sold;
					</li>
					<li>the right to “opt-out” of WAX’s sale of your personal information to any third parties;</li>
					<li>the right, with some exceptions, to have your personal information deleted from WAX’s possession or control; and</li>
					<li>the right to receive equal service and pricing from WAX even if you exercise any of your privacy rights.</li>
				</ul>
				<h2>General Provisions</h2>
				<p>This Agreement is the entire and exclusive agreement between WAX and you regarding the Service, and this Agreement supersedes and
					replaces any prior agreements or understandings between WAX and you regarding the Site and Service.</p>
				<p>If a particular provision of this Agreement is held to be invalid by a court of competent jurisdiction, the provision shall be
					deemed to be severed from this Agreement and shall not affect the validity of this Agreement as a whole, which shall remain in
					full force and effect.</p>
				<p>You shall not assign or otherwise transfer this Agreement or any of your rights or obligations hereunder to any third party without
					the prior written consent of WAX, which consent is within WAX’ sole and unfettered discretion. No assignment or delegation by you
					shall relieve or release you from any of your obligations under this Agreement. Subject to the foregoing, this Agreement shall be
					binding upon, inure to the benefit of, and be enforceable by each of the Parties and their respective successors and permitted
					assigns. WAX shall be allowed to assign this Agreement to any third party without requiring your consent.</p>
				<p>Nothing in this Agreement shall constitute a partnership or joint venture between you and WAX.</p>
				<p>The failure of WAX to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or
					provision. Any waiver of this Agreement or any party hereof by WAX must be in writing and signed by an authorized representative
					of WAX.</p>
				<h2>Contact</h2>
				<p>If you have any concern, question or complaint regarding this Agreement, please contact WAX at WAX, Floor 4, Willow House, Cricket
					Square, Grand Cayman KY1-9010, Cayman Islands. WAX is a tradename of Exposition Park Holdings.</p>
				<br />
				<p>Date of Last Revision: June 22, 2020</p>
			</div>
		)
	}
}
