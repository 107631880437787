import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';

import * as API from '../../helpers/API';
import Button from '../../components/Button/Button';

import qs from "querystring";

import { Link, withRouter } from 'react-router-dom'

import "./Inventory.css";
import InventoryView from '../../components/Inventory/InventoryView';
import UnpackIntro from '../../components/Inventory/UnpackIntro';
import TabSelector from '../../components/TabSelector';
import config from '../../config/config';
import Swal from 'sweetalert2';
import replaceIpfsHash from "../../helpers/ipfsDebugger";

class Inventory extends Component {

	_mounted = false;

	currentPackTitle;
	currentCardTitle;

	constructor(props) {
		super(props);
		let queries = qs.parse(this.props.location.search.substr(1));
		let accountName = props.accountName ? props.accountName : props.userAccount;
		this.currentCardTitle = `Cards`
		this.currentPackTitle = `Unopened Packs`
		if (props.userAccount === accountName) {
			this.currentCardTitle = `Your Cards`
			this.currentPackTitle = `Your Unopened Packs`
		} else {
			this.currentCardTitle = `${accountName}'s Cards`
			this.currentPackTitle = `${accountName}'s Unopened Packs`
		}
		let tab = this.currentPackTitle;
		if (queries.tab === "cards") tab = this.currentCardTitle;
		if (queries.tab === "packs") tab = this.currentPackTitle;
		this.initialTab = tab;
		this.state = {
			side: tab,
			tabs: [this.currentPackTitle, this.currentCardTitle],
			unclaimedCrafts: []
		}
		this.unpackIntroRef = React.createRef();
	}

	componentDidMount() {
		this._mounted = true;
		let accountName = this.props.accountName ? this.props.accountName : this.props.userAccount;
		if (this.props.userAccount === accountName) {
			this.loadUnopenedPacks(accountName);
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	loadUnopenedPacks = accountName => {
		API.getUnopenedPendingNfts(accountName).then(rows => {
			if (this._mounted && rows) {
				const pendingCrafting = rows.filter(x => {
					return (['base', 'shimmer', 'xray'].includes(x.rarity));
				});
				if (pendingCrafting.length > 0) {
					this.setState({
						unclaimedCrafts: pendingCrafting
					})
				} else {
					this.setState({ unopenedPacks: rows.map(row => row.unboxingid) })
				}
			}
		}).catch(console.error)
	}

	claimUnclaimedCrafts = async () => {
		const response = await Swal.fire({
			title: 'Claim Cards!',

			showLoaderOnConfirm: true,
			preConfirm: async (login) => {
				const unclaimed = this.state.unclaimedCrafts.pop();
				this.setState({
					unclaimedCrafts: [...this.state.unclaimedCrafts]
				})
				Swal.showLoading();

				const result = await API.createnfts(global.wax.userAccount, unclaimed.unboxingid);
				return result;
			},
			confirmButtonText: 'Ok'
		});

		if (response.value && response.value.unboxedNfts.length > 0) {
			await Swal.fire({
				icon: 'success',
				html: `<img height="350px" src="${config.getIpfsUrl(replaceIpfsHash(JSON.parse(response.value.unboxedNfts[0].mdata).img))}"/>`,
				confirmButtonText: 'Ok'
			});
		}
	}

	render() {
		if (!config.settings.HAS_PACKS) {
			return (
				<div className="container" id="inventory">
					<InventoryView accountName={this.props.accountName ? this.props.accountName : this.props.userAccount} />
				</div>
			);
		}

		console.log('unopened packs', this.state.unopenedPacks);

		return (
			<div className="container" id="inventory">
				<UnpackIntro ref={this.unpackIntroRef} />
				{
					this.state.unopenedPacks && this.state.unopenedPacks.length > 0 ?
						<Link to={"/open/" + this.state.unopenedPacks[0]}><Button style={{ width: "100%" }}>You have unclaimed cards from previous
							pack openings</Button></Link> : null
				}
				{
					!this.props.noCatalog ?
						<div className={"mobile extralinks"}>
							<Link className={"arrow_right"} to={"/catalog"}>Catalog</Link>
						</div>
						: null
				}
				<div className={"title"}><i>Inventory</i></div>
				<TabSelector defaultTab={this.initialTab} tabs={this.state.tabs} onChange={tab => this.setState({ side: tab })} />
				{
					this.state.side === this.currentPackTitle ?
						<div className={"wrapper"}>
							<div className={"title"}>
								{this.currentPackTitle}
							</div>
							<InventoryView
								accountName={this.props.accountName ? this.props.accountName : this.props.userAccount}
								collection={config.packCollectionName}
								schemaWhitelist={config.packSchema}
								onClick={(pack) => {
									this.unpackIntroRef.current.showConfirmation(pack)
								}}
							/>
						</div>
						: null
				}
				{
					this.state.side === this.currentCardTitle ?
						<div className={"wrapper"}>
							<div className={"title"}>
								{this.currentCardTitle}
							</div>
							<InventoryView
								accountName={this.props.accountName ? this.props.accountName : this.props.userAccount}
								collection={config.cardCollectionName}
								schemaWhitelist={config.cardSchema} />
						</div>
						: null
				}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		userAccount: state.userAccount
	}
}

export default connect(mapStateToProps)(withRouter(Inventory));