import Axios from "axios"
import EnvHandler from "../EnvHandler";

const prod = !EnvHandler.isDevMode();

const endpoint = {
    CREATE_PAYMENT_TEST: "https://pay.wax.io/v1test/intent/create",
    CREATE_PAYMENT_PROD: "https://pay.wax.io/v1/intent/create",
    CREATE_CHECKOUT_TEST: "https://pay.wax.io/v1test/checkout/create",
    CREATE_CHECKOUT_PROD: "https://pay.wax.io/v1/checkout/create",
    // New
    CREATE_ADDRESS_CHECKOUT_TEST: "https://pay.wax.io/v1test/pay",
    CREATE_ADDRESS_CHECKOUT_PROD: "https://pay.wax.io/v1/pay",
};

const createPamyent = (quantity, token, accountName) => {
    return Axios.post(prod ? endpoint.CREATE_PAYMENT_PROD : endpoint.CREATE_PAYMENT_TEST, {
        "amount": parseInt(quantity),
        accountName,
        "pack": token,
        "method": "card"
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    })
}

const createCheckout = (quantity, pack, accountName, price) => {
    return Axios.post(prod ? endpoint.CREATE_CHECKOUT_PROD : endpoint.CREATE_CHECKOUT_TEST, {
        "successUrl": window.location.origin + "/payment",
        "cancelUrl": window.location.origin + "/shop",
        pack,
        accountName,
        quantity,
        price
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    })
}

const createAddressBasedCheckout = (customerEmail, address, quantity, price, accountName) => {
    return Axios.post(prod ? endpoint.CREATE_ADDRESS_CHECKOUT_PROD : endpoint.CREATE_ADDRESS_CHECKOUT_TEST, {
        "successUrl": window.location.origin + "/payment",
        "cancelUrl": window.location.origin + "/shop",
        address,
        price,
        accountName,
        quantity,
        customerEmail
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    })
}


export default {
    createPamyent,
    createCheckout,
    createAddressBasedCheckout
}