import React, { Component } from 'react';

import { connect } from "react-redux";

import * as API from "../../helpers/API";
import * as CompabilityAPI from '../../helpers/Compability';

import "./Trades.css";
import { Link } from "react-router-dom";

import TradeIcon from '../../assets/icons/trade.svg';
import ActionCard from '../../components/ActionCard';
import AuxLayout from '../../helpers/AuxLayout';
import Button from '../../components/Button/Button';
import config from '../../config/config';

import AtomicAssetsAPI from '../../helpers/AtomicAssetsAPI';

class Trades extends Component {

    _mounted = false;

    state = { sentOffers: [], pendingOffers: [] };

    componentDidMount() {
        this._mounted = true;
        this.fetchTrades()
        let device = CompabilityAPI.getDeviceInformation();
        let interval = 3000;
        if (device.os === CompabilityAPI.OS.IOS || device.os === CompabilityAPI.OS.ANDROID) interval = 6000;
        if (device.os === CompabilityAPI.OS.MAC) interval = 4000;
        this.interval = setInterval(this.fetchTrades, interval);
    }


    fetchTrades = () => {
        AtomicAssetsAPI.getOffers({
            account: global.wax.userAccount,
            state: 0
        }).then(offers => {
            offers = offers.filter(offer => {
                let valid = true;
                let assets = [...offer.sender_assets, ...offer.recipient_assets];
                for (let asset of assets) {
                    if (asset.collection.collection_name !== config.collection_name) {
                        valid = false;
                    }
                }
                return valid;
            })
            let sentOffers = offers.filter(offer => offer.sender_name === global.wax.userAccount);
            let pendingOffers = offers.filter(offer => offer.recipient_name === global.wax.userAccount);
            if (this._mounted) this.setState({ sentOffers, pendingOffers })
        });
    }

    componentWillUnmount() {
        this._mounted = false;
        clearInterval(this.interval)
    }

    render() {
        console.log(this.state.sentOffers)
        let pendingOffers = this.state.pendingOffers.map(offer => (
            <Link key={offer.offer_id} to={"/offer/" + offer.offer_id}>
                <div className="trade">
                    <img src={config.media.LOGO} alt="" className="avatar" />
                    <b className="name">{offer.sender_name}</b><br />
                    <small className="action">sent you a trade offer (#{offer.offer_id})</small>
                </div>
            </Link>
        ));
        let sentOffers = this.state.sentOffers.map(offer => (
            <Link key={offer.offer_id} to={"/offer/" + offer.offer_id}>
                <div className="trade">
                    <img src={config.media.LOGO} alt="" className="avatar" />
                    <div><b className="name">{offer.recipient_name} </b></div><small className="action">You sent {offer.sender_assets.length} item{offer.sender_assets.length !== 1 ? "s" : ""} in a trade offer. (#{offer.offer_id})</small>
                </div>
            </Link>
        ));
        return (
            <div className="container" id="trades">
                <Link to={"/trade"}><Button className={"full"}>Send Offer</Button></Link>
                {
                    pendingOffers.length > 0 ?
                        <AuxLayout>
                            <div className={"title"} id="pending">
                                <b>Pending Offers</b>
                            </div>
                            <div className={"box"}>
                                <div className="trades-list">
                                    {pendingOffers}
                                </div>
                            </div>
                        </AuxLayout>
                        : null
                }
                <div className={"title"} id="sent">
                    <b>Trade Offers You've Sent</b>
                </div>
                <div className={"box"}>
                    <div className="trades-list">
                        {sentOffers.length > 0 ? sentOffers : <Link to={"/trade"}><ActionCard className={"full small"} img={TradeIcon} text={"Send Offer"} /></Link>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(Trades);