import React, { Component } from 'react'
import Swal from 'sweetalert2'

import "./Inventory.css";
import * as API from "../../helpers/API";
import { Redirect } from 'react-router-dom';
import DimmingVideo from "./DimmingVideo";

export default class UnpackIntro extends Component {

	_mounted = false;

	state = {
		loaded: false,
		packs: [],
		redirect: undefined,
		unpackConfirmation: false,
		showVideo: false,
		assoc_id: null,

		// Filter bar
		type: "",
		search: "",
		series: "",
		special_filter: ""
	}
	assocId = null;
	videoStarted = 0;


	componentDidMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	unpack = async pack => {
		if (this._mounted) {
			this.startVideo();
			this.setState({
				showVideo: true
			})
			try {
				let { processed } = await API.unbox(global.wax.userAccount, pack);
				if (processed && processed.action_traces && processed.action_traces.length > 0) {
					let at = processed.action_traces;
					for (let i = 0; i < at.length; i++) {
						if (at[i] && at[i].inline_traces && at[i].inline_traces.length > 0) {
							let it = at[i].inline_traces;
							for (let j = 0; j < it.length; j++) {
								if (it[j].receiver === "orng.wax" && it[j].act && it[j].act.data && it[j].act.data.assoc_id != null) {
									this.assocId = it[j].act.data.assoc_id;
									if (this.assocId && this.videoStarted === -1) {
										this.tryRedirect();
									}

									setTimeout(() => {
										if (!this.redirected) {
											this.redirected = true;
											return this.setState({ redirect: "/open/" + this.assocId })
										} else {
											console.log('already redirected')
										}
									}, 1000);
								}
							}
						}
					}
				}
			} catch (e) {
				console.error(e)
			}
		}
	}

	startVideo = () => {
		if (this._mounted) {
			this.refs.animationPlayer.play().then(() => {
				this.refs.animationPlayer.pause()
				this.setState({
					showVideo: true
				});
				setTimeout(() => {
					this.refs.animationPlayer.play()
				}, 1000);
			}).catch(console.error)
		}
	}

	tryRedirect() {
		if (this.assocId && this._mounted) {
			if (!this.redirected) {
				this.redirected = true;
				return this.setState({ redirect: "/open/" + this.assocId })
			} else {
				console.log('already redirected in tryRedirect');
			}
		} else {
			setTimeout(() => {
				this.tryRedirect();
			}, 20);
		}
	}

	videoEnded() {
		this.tryRedirect()
	}

	showConfirmation = async (pack) => {
		console.log('pack', pack);
		const result = await Swal.fire({
			title: 'Open Pack?',
			html: `<p>Please confirm that you want to open the <b>${pack.data.name} Pack</b>.</p>`,
			icon: 'question',
			confirmButtonText: 'Open',
			showCancelButton: true,
			CancelButtonText: 'Back'
		});

		if (result.value === true) {
			await this.unpack(pack)
		}
	}

	render() {
		if (this.state.redirect) return <Redirect to={this.state.redirect} />

		return (
			<div className={this.state.showVideo ? "unpacking-dimmer" : "unpacking-dimmer-hidden"}>
				<video
					ref="animationPlayer"
					id="dimming-video"
					playsInline
					onEnded={() => {
						this.videoEnded()
					}}>
					<DimmingVideo />
					Your browser does not support the HTML5 player.
				</video>
			</div>
		)
	}
}
