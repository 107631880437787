import React, { Component } from 'react'
import * as SocialAPI from '../../helpers/contracts/social';
import * as ActivityAPI from '../../helpers/activity';
import * as API from '../../helpers/API';

import {connect} from 'react-redux';

import "./Community.css";

import FollowingItem from '../../components/Community/FollowingItem';

import Loader from '../../components/Loader';

import ActivityFeed from '../../components/ActivityFeed';
import ItemView from '../../components/ItemView';
import { Link } from 'react-router-dom';

import AtomicAssetsAPI from '../../helpers/AtomicAssetsAPI';
import config from '../../config/config';
import replaceIpfsHash from '../../helpers/ipfsDebugger';

class Community extends Component {

    _mounted = false;

    state = {

        activeUsers: [],
        activeUsersLoading: true,

        recentlyOpenedCards: [],
        recentlyOpenedCardsLoading: true,

        communityActions: [],
        communityActionsLoading: true,

        searchQuery: "",
        searchResults: [],
        searchLoaded: false
    }

    componentDidMount () {
        this._mounted = true;
        this.loadRecentlyActiveUsers();
        this.loadRecentlyUnpackedCards();
    }

    componentWillUnmount () {
        this._mounted = false;
    }

    loadRecentlyActiveUsers = () => {
        if (!this._mounted) return;
        ActivityAPI.getCommunityActions().then(contractResults => {
            let tempContractResults = []
            let activeAccounts=[]
            // Recently Active Users
            for (let i = 0; i<contractResults.length; i++) {
                let contractData = contractResults[i];
                tempContractResults = [
                    ...tempContractResults,
                    ...contractData,
                ]
                for (let j = 0; j<contractData.length; j++) {
                    let tx = contractData[j];
                    activeAccounts = [
                        ...activeAccounts,
                        ...tx.auth
                    ];
                }
            }
            tempContractResults = tempContractResults.sort((a, b) => {
                return new Date(a.time).getTime() < new Date(b.time).getTime();
            })
            let activeUsers = Array.from(new Set(activeAccounts))
            if (!this._mounted) return;
            this.setState({communityActions: tempContractResults, communityActionsLoading: false, activeUsers: activeUsers, activeUsersLoading: false})
        }).catch(console.error)
        
    }

    loadRecentlyUnpackedCards = () => {
        if (!this._mounted) return;
        AtomicAssetsAPI.getAssets({
            collection_name: config.collection_name,
            schema_name: "series1",
            sort: "minted",
            limit: 10
        }).then (res => {
            this.setState({recentlyOpenedCards: res, recentlyOpenedCardsLoading: false})
        })
        setTimeout(this.loadRecentlyUnpackedCards, 3000)
    }

    searchInput = e => {
        this.setState({searchQuery: e.target.value})
            SocialAPI.findWalletByName(e.target.value).then(res => {
                if (res.rows) {
                    this.setState({ searchResults: res.rows.map(row => row.scope).filter(name => name.endsWith(".wam")), searchLoaded: true })
                };
            })
    }

    render() {
        return (
            <div className="container" id="community">
            <div className={"wrapper"}>
                <div className={"title"}>
                    <b>Search</b> for Community Members
                </div>
                <div className="box" id="FilterBar">
                    <input type="search" onKeyDown={this.searchInput} onInput={this.searchInput} placeholder="Search for someone..." style={{ width: "100%" }}/>
                </div>
                {
                    this.state.searchResults.length > 0 && this.state.searchQuery.length > 0? 
                                <div className="box" id="">
                                    <div className="following-wrapper" style={{display: "flex", overflowY: "auto"}}>
                                        {
                                            this.state.searchResults.map((accountName, i) => <FollowingItem key={i} accountName={accountName}/>)
                                        }
                                    </div>
                                </div>
                    : 
                    null
                }
                {
                    this.state.searchLoaded && this.state.searchQuery.length > 0 && this.state.searchResults.length == 0 ?
                    <small>No results found</small>
                    : null
                }
            </div>


            <div className={"wrapper"}>
                <div className={"title"}>
                    <b>Active</b> Community Members
                </div>
                {
                    this.state.activeUsersLoading ? 
                    <Loader /> 
                    : 
                    <div className="box" id="">
                        <div className="following-wrapper" style={{display: "flex", overflowY: "auto"}}>
                            {
                                this.state.activeUsers.map((accountName, i) => <FollowingItem key={i} accountName={accountName}/>)
                            }
                        </div>
                    </div>
                }
            </div>
                
                <div className={"wrapper"}>
                    <div className={"title"}>
                        Recently Opened Cards
                    </div>
                            {
                                this.state.recentlyOpenedCardsLoading ?
                                <Loader />
                                : 
                                <div className="box" id="">
                                    <div className={"cards-wrapper"}>
                                        { this.state.recentlyOpenedCards.map((nft, i) => {
                                            let name = nft.name;
                                            let img = replaceIpfsHash(nft.data.img);
                                            return <Link key={i} to={`/p/${nft.owner}/?tab=cards`}><ItemView name={name} image={img} /></Link>
                                        }) }
                                    </div>
                                </div>
                        }
                </div>
                <div className={"wrapper"}>
                    {
                        this.state.communityActionsLoading ?
                            <Loader />
                        : 
                            <ActivityFeed title={"Recent Events in the WAX Community"} recentActions={this.state.communityActions} />
                    }
                    </div>
            </div>
        )
    }
}

const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(Community);