import BigNumber from 'big-number';
import { Numeric, Serialize } from 'eosjs';
import CatalogHelper from './CatalogHelper';

import Packs from './packs';

import Config from '../config/config';
import EnvHandler from './EnvHandler';

export const { packs, contracts } = Config;

export const UNPACKING_CONTRACT = contracts.UNPACKING;
export const TOKEN_CONTRACT = contracts.TOKEN;
export const SOCIAL_CONTRACT = contracts.SOCIAL;


export const TOKEN_NAMES = Config.packs.TOKEN_NAMES
export const PACK_TYPES = Config.packs.PACK_TYPES


const PENDING_TABLE = 'pending.a';
const RNGJOBS_TABLE = 'rngjobs.a';

export const UNBOXING_STATUS = {
	PENDING: 'pending',
	CREATING: 'creating',
	DONE: 'done',
	ALREADY_UNPACKED: 'already_unpacked'
}

export const numericFromName = (accountName) => {
	const sb = new Serialize.SerialBuffer({
		textEncoder: new TextEncoder(),
		textDecoder: new TextDecoder()
	})

	sb.pushName(accountName)

	return Numeric.binaryToDecimal(sb.getUint8Array(8));
}

const getPendingNfts = (accountName, unboxingID) => {
	return new Promise((resolve, reject) => {
		let lb = parseInt(unboxingID) * Math.pow(10, 3)
		global.wax.rpc.get_table_rows({
			json: true,
			code: UNPACKING_CONTRACT,
			scope: accountName,
			table: PENDING_TABLE,
			lower_bound: lb,
			upper_bound: lb + 100,
			limit: 100,
		}).then(result => resolve(result.rows)).catch(err => {
			reject(err)
		});
	});
}

function shuffle(a) {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[a[i], a[j]] = [a[j], a[i]];
	}
	return a;
}


export const getUnboxingStatus = async (accountName, unboxingID) => {
	const rngjob = await global.wax.rpc.get_table_rows({
		json: true,
		code: UNPACKING_CONTRACT,
		scope: UNPACKING_CONTRACT,
		table: RNGJOBS_TABLE,
		lower_bound: unboxingID,
		upper_bound: unboxingID + 1,
		limit: 100,
	}).then(x => x.rows);

	/*only happens if the rngjob doesn exist yet*/
	if (!rngjob[0]) {
		await new Promise(r => setTimeout(r, 100));
		return await getUnboxingStatus(accountName, unboxingID);
	}

	if (rngjob[0].status === UNBOXING_STATUS.PENDING) {
		return {
			pendingNFts: [],
			status: UNBOXING_STATUS.PENDING
		}
	} else {
		const pendingNfts = await getPendingNfts(accountName, unboxingID);
		if (pendingNfts.length) {
			return {
				pendingNfts,
				status: UNBOXING_STATUS.CREATING
			}
		} else {
			return {
				pendingNFts: [],
				status: UNBOXING_STATUS.DONE
			}
		}
	}
}

async function _createnfts(accountName, unboxingID, ids) {
	let data = {
		from: accountName,
		unboxing: unboxingID,
		cardids: ids.map(x => x.id)
	}
	console.log(data);
	const receipt = await global.wax.api.transact({
		actions: [
			{
				account: UNPACKING_CONTRACT,
				name: 'getcards',
				authorization: [{
					actor: accountName,
					permission: 'active',
				}],
				data,
			}
		]
	}, {
		blocksBehind: 15,
		expireSeconds: 3600,
	});
	let mintingActions = receipt.processed.action_traces[0].inline_traces;
	mintingActions = mintingActions.filter(action => action.act.account == "atomicassets" && action.act.name === "mintasset");

	const nfts = [];
	for (let mintingAction of mintingActions) {
		let logMints = mintingAction.inline_traces;
		logMints = logMints.filter(action => action.act.account === "atomicassets" && action.act.name === "logmint");
		let nftDataArr = logMints.map(action => action.act.data)
			.map(nft => {
				let nftData = {};
				nftData.asset_id = nft.asset_id;
				nftData.collection_name = nft.collection_name;
				nftData.data = {}
				let tempData = nft.immutable_template_data;
				for (let tdata of tempData) {
					let { key, value } = tdata;
					let [x, val] = value;
					nftData.data[key] = val;
					if (key === "name") {
						data.name = val;
					}
				}
				nftData.owner = nft.new_asset_owner;
				nftData.schema_name = nft.schema_name;
				nftData.template_id = nft.template_id;
				return nftData;
			});
		nfts.push(...nftDataArr)
	}
	console.log(nfts)

	return nfts;
}

export const createnfts = async (accountName, unboxingID, max = 1000, unboxingStatus) => {
	if (!unboxingStatus) {
		unboxingStatus = await getUnboxingStatus(accountName, unboxingID);
		unboxingStatus.unboxedNfts = [];
	}

	if (unboxingStatus.status === UNBOXING_STATUS.CREATING) {
		const shuffledNfts = shuffle(unboxingStatus.pendingNfts).filter(({ done }) => !done);
		if (shuffledNfts.length == 0) return {
			pendingNFts: [],
			unboxedNfts: [],
			status: UNBOXING_STATUS.ALREADY_UNPACKED
		}
		const results = [];
		try {
			results.push(await _createnfts(accountName, unboxingID, shuffledNfts));
		} catch (e) {
			console.log(e)
			while (shuffledNfts.length) {
				const chosenNfts = shuffledNfts.splice(0, max);
				results.push(await _createnfts(accountName, unboxingID, chosenNfts))
			}
		}
		const nfts = [];
		for (let i = 0; i < results.length; i++) {
			for (let j = 0; j < results[i].length; j++) {
				nfts.push(results[i][j]);
			}
		}

		return {
			...unboxingStatus,
			unboxedNfts: nfts
		}
	} else {
		return unboxingStatus
	}
}

export const getBoxType = async (accountName, unboxingID) => {
	return global.wax.rpc.get_table_rows({
		json: true,
		code: UNPACKING_CONTRACT,
		scope: UNPACKING_CONTRACT,
		table: RNGJOBS_TABLE,
		lower_bound: unboxingID,
		upper_bound: unboxingID + 1,
		limit: 100,
	}).then(x => x.rows[0]['boxtype']);
}

export const unbox = async (accountName, pack) => {
	let packType = pack.type
	console.log('packType', packType);
	console.log(parseInt(pack.template.template_id), Config.packs.PACK_TEMPLATE_IDS);
	if (!Config.packs.PACK_TEMPLATE_IDS.includes(parseInt(pack.template.template_id)))
		throw Error("invalid pack type");

	const actions = [
		{
			account: UNPACKING_CONTRACT,
			name: 'unbox',
			authorization: [{
				actor: accountName,
				permission: 'active',
			}],
			data: {
				from: accountName,
				pack: Config.packs.PACK_NAMES[pack.template.template_id]
			},
		}
	];

	actions.unshift({
		account: 'atomicassets',
		name: 'transfer',
		authorization: [{
			actor: accountName,
			permission: 'active',
		}],
		data: {
			from: accountName,
			to: UNPACKING_CONTRACT,
			asset_ids: [pack.asset_id],
			memo: ''
		},
	})
	console.log('actions', actions);
	const receipt = await global.wax.api.transact({
		actions
	}, {
		blocksBehind: 10,
		expireSeconds: 120,
	});

	return receipt;
}

export const buyPacks = (quantity, template_id, price) => {
	return new Promise(async (resolve, reject) => {
		try {
			let accountName = global.wax.userAccount;
			let acts = [{
				account: "eosio.token",
				name: 'transfer',
				authorization: [{
					actor: accountName,
					permission: 'active',
				}],
				data: {
					from: accountName,
					to: Config.issueContract,
					quantity: price,
					memo: "Buy Pack",
				},
			},
				{
					account: Config.issueContract,
					name: 'buytaa',
					authorization: [{
						actor: accountName,
						permission: 'active',
					}],
					data: {
						buyer: accountName,
						template_id,
						quantity,
					},
				}]
			let result = await global.wax.api.transact({
				actions: acts
			}, {
				blocksBehind: 10,
				expireSeconds: 120,
			});
			resolve(result)
		} catch (err) {
			reject(err)
		}
	})
}


export const denyOffer = (offer_id) => {
	let acts = [
		{
			account: 'atomicassets',
			name: 'declineoffer',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				"offer_id": offer_id,
			}
		},
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(async result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const acceptOffer = (offer_id) => {
	let acts = [
		{
			account: 'atomicassets',
			name: 'acceptoffer',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				"offer_id": offer_id,
			}
		},
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(async result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const getUnopenedPendingNfts = async (accountName, lower_bound = null, temp = []) => {
	return new Promise((resolve, reject) => {
		global.wax.rpc.get_table_rows({
			json: true,
			code: UNPACKING_CONTRACT,
			scope: accountName,
			table: PENDING_TABLE,
			lower_bound: lower_bound,
			limit: 100,
		}).then(async res => {
			let rows = res.rows;
			let lastLowerBound = 0;
			let merged = [...temp, ...rows];
			if (res.more) {
				lastLowerBound = res.rows[res.rows.length - 1].id + 1;
				return resolve(await getUnopenedPendingNfts(accountName, lastLowerBound, merged))
			}
			merged = merged.filter(row => {
				return row.done === 0
			});
			resolve(merged)
		}).catch(err => {
			reject(err)
		});
	});
}
export const cancelOffer = (offer_id) => {
	let acts = [
		{
			account: 'atomicassets',
			name: 'canceloffer',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				"offer_id": offer_id,
			}
		},
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(async result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const createOffer = (partner, items_to_send, items_to_receive, memo) => {
	let data = {
		sender: global.wax.userAccount,
		recipient: partner,
		sender_asset_ids: items_to_send,
		recipient_asset_ids: items_to_receive,
		memo
	};
	console.log(data)
	let acts = [
		{
			account: 'atomicassets',
			name: 'createoffer',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data
		},
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(async result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}
export const getCatalog = (series) => {
	return new Promise(resolve => {
		let arr = CatalogHelper.getSeries(series);
		resolve(arr);
	});
}
