import React, { Component } from 'react'
import AuxLayout from '../../helpers/AuxLayout';
import EnvHandler from '../../helpers/EnvHandler';
import qs from 'querystring';

const liveKey = "pk_live_5N8ybnxEf68NUJZclUwaG4yUfpFyFq";

export default class MoonPayIntegration extends Component {

    state = {signature: null, originalUrl: null, fetchQuery: {}, loadingFetch: false}

    createSig = query => {
        this.setState({loadingFetch: true,fetchQuery: query}, () => {
            fetch("https://moonpay.wax.io/create?" + qs.stringify(query)).then(res => res.json()).then(res => {
                this.setState({...res, loadingFetch: false})
            })
        })
    }

    getQuery = () => {
        let amount = this.props.amount;
        let qData = {
            apiKey: liveKey,
            currencyCode: "WAXP",
            walletAddress: global.wax.userAccount,
            baseCurrencyAmount: parseFloat(amount),
            baseCurrencyCode: "USD"
        };
        return qData;
    }

    render() {
        let query = this.getQuery();
        console.log("?" + qs.stringify(query) === this.state.originalUrl);
        if (!this.state.loadingFetch && JSON.stringify(this.state.fetchQuery) !== JSON.stringify(query)) {
            this.createSig(query)
        }
        const signature = `&signature=${encodeURIComponent(this.state.signature)}`;
        let url = `https://buy.moonpay.io/${this.state.originalUrl + signature}`;
        console.log(url)
        return (
            <AuxLayout>
                <div className={"dimmer active"}></div>
                <div className={"popup moonpay active"}>
                    {
                        this.state.signature && JSON.stringify(this.state.fetchQuery) === JSON.stringify(query) && !this.state.loadingFetch?
                            <iframe frameBorder={0} src={url}></iframe>
                        : "loading..."
                    }
                </div>
                { this.props.onClose ?<div className="popup-closer" onClick={this.props.onClose}>Close</div> : null }
            </AuxLayout>
        )
    }
}
