import React, { Component } from 'react'

import { isAndroid, isChrome, isChromium, isIOS } from 'react-device-detect';

import * as VanillaTilt from 'vanilla-tilt';

import './Pack.css';

class Pack extends Component {


	sizerElement = React.createRef();

	constructor(props) {
		super(props);

		let back = ``;
		if (props.back) back = props.back;

		this.state = {
			showBackside: false,
			back: back,

			width: props.width ? props.width : 0,
			height: props.height ? props.height : 0,
			forcedSizes: props.width !== null || props.height !== null,

			cleanImg: this.props.src,

			isTitan: (this.props.src.indexOf('30.png') > 0)
		}

	}

	componentDidMount() {
		this.initTilt();
		this.resizeListener = window.addEventListener("resize", () => {
			this.updateSizes();
		})
	}

	componentWillUnmount() {
		if (this.resizeListener) {
			window.removeEventListener("resize", this.resizeListener)
			delete this.resizeListener;
		}
	}

	componentDidUpdate() {
		this.updateSizes();
	}

	initTilt_(node) {
		VanillaTilt.init(node, {
			max: 25,
			gyroscope: true,
			startY: 9,

			perspective: 1400,
			easing: "cubic-bezier(.03,.98,.52,.99)",
			speed: (isChrome || isChromium) ? 1200 : 0,
			glare: true,
			maxGlare: 0.05,
			scale: 1.07
		});
	}

	initTilt() {
		this.initTilt_(this.rootNode);
		this.initTilt_(this.rootNodeBack);
	}

	updateSizes = () => {
		if (this.sizerElement && this.sizerElement.current && this.state.width !== this.sizerElement.current.width) {
			this.setState({
				width: this.sizerElement.current.width,
				height: this.sizerElement.current.height
			})
		}
	}

	render() {

		if (isIOS || isAndroid) {
			return (
				<img src={this.props.src} style={{ maxWidth: '100%' }} />
			)
		}

		return (
			<div className={"tilt3-wrapper tilt3-flip-back"} style={{
				display: "inline-block",
				transform: "translateZ(-1px)",
				WebkitTransform: "translateZ(-1px)",
				msTransform: "translateZ(-1px)"
			}}>
				<img src={this.props.src}
					 alt=""
					 style={{ maxWidth: '100%' }}
					 ref={this.sizerElement}
					 onLoad={() => this.updateSizes()}
					 className="tilt3-img-sizer"
				/>
				<div className={"tilt3-front " + this.state.packClass} style={{ width: `${this.state.width}px`, height: `${this.state.height}px` }}>
					<div className="tilt3-rotator" ref={node => (this.rootNode = node)}>
						<div className="card-pack" style={{ backgroundImage: `url(${this.state.cleanImg})` }}>

						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default Pack;
