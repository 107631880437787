import config from '../config/config';

const contracts = config.contracts_to_track;

export const getRecentActions = (accountName, customContracts = ({})) => {
    return new Promise((resolve, reject) => {
        if (!accountName) return reject("No name defined");
        fetch(`https://api.waxsweden.org/v2/history/get_actions?filter=${config.contracts.UNPACKING}:unbox&account=${accountName}`).then(res => res.json())
            .then((res) => {
                console.log(res)
                let actions = res.actions;
                actions = actions
                    .map(action => {
                        let {account, data, name, authorization} = action.act;

                        // TimeZoneFix
                        let oneHour = 1000 * 60 * 60;
                        let defaultDate = new Date(action.timestamp);
                        // let offsetHours = new Date().getTimezoneOffset() / 60;
                        // let timeZoneFixedMs = defaultDate.getTime() + (offsetHours * oneHour)
                        // let timeZoneFixedDate = new Date(timeZoneFixedMs);

                        let time = defaultDate;



                        let auth = authorization.map(auth => auth.actor);
                        let tx = action.trx_id;
                        return {tx, account, data, name, time, auth}
                    })
                    .filter(action => {
                        if (JSON.stringify(customContracts) !== JSON.stringify({})) return customContracts[action.account] != null && customContracts[action.account].includes(action.name)
                        else return contracts[action.account] != null && contracts[action.account].includes(action.name)
                    })
                    .sort((a, b) => b.time.getTime() - a.time.getTime());
                resolve(actions);
            }).catch(console.error)
    });
}

export const getCommunityActions = () => {
    return new Promise(async (resolve, reject) => {
        let cs = Object.keys(contracts);
        // let activeAccounts = []

        const promises = [];
        for (let i = 0; i < cs.length; i++) {
            promises.push(getRecentActions(cs[i]));
        }
        let contractResults = await Promise.all(promises);
        resolve(contractResults);
    });
}
