import React, { Component } from 'react';
import * as actionTypes from "../../store/actionTypes";

import { connect } from "react-redux";

import "./Shop.css";
import { Link, NavLink } from "react-router-dom";
import Loader from '../../components/Loader';

import Pack from '../../components/Pack/Pack';
import ShopHelper from '../../helpers/lib/ShopHelper';
import PurchaseForm from '../../components/Shop/PurchaseForm';
import StripeHandler from '../../helpers/StripeHandler';

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import Swal from 'sweetalert2';
import { contracts } from '../../helpers/API';
import AnimatedNumber from "animated-number-react";

import AtomicAssetsAPI from "../../helpers/AtomicAssetsAPI";

import querystring from 'querystring';

class Shop extends Component {

	_mounted = false;

	state = {
		loaded: false,
		packs: [],
		purchase: null,
		requireLogin: false,
		quantity: {},
		tokenSupply: {},
		cfdata: {
			loc: "US"
		},
	}

	componentDidMount() {
		this._mounted = true;
		this.loadPacks();
		this.loadCountry();
	}

	loadCountry = () => {
		fetch("https://www.cloudflare.com/cdn-cgi/trace")
			.then(response => response.text())
			.then(res => res.split("\n").join("&"))
			.then(res => {
				let cfdata = querystring.parse(res);
				this.setState({ cfdata })
			})
	}

	loadPacks = async () => {
		ShopHelper.getProducts().then(async packs => {
			for (let i = 0; i < packs.length; i++) {
				let pack = packs[i];
				let template = await AtomicAssetsAPI.getTemplate(pack.collection, pack.templateId);
				packs[i].supply = parseFloat(template.max_supply) - parseFloat(template.issued_supply);
			}
			if (!this._mounted) return;
			this.setState({ loaded: true, packs })
			setTimeout(this.loadPacks, 5000);
		}).catch(console.error)
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	login = async () => {
		await Swal.showValidationMessage("Test")
		let userAccount = await global.wax.login();
		if (userAccount) {
			this.props.onAuthUpdate(userAccount);
		}
		global.wax.whitelistedContracts = global.whitelistedContracts;
	}

	purchase = pack => {
		this.setState({ purchase: pack })
	}

	modifyQuantity = (slug, modifier, e) => {
		let { quantity } = this.state;
		quantity[slug] += modifier;
		if (quantity[slug] < 1) quantity[slug] = 1;
		if (!this._mounted) return;
		this.setState({ quantity })
	}

	formatNumber = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

			const negativeSign = amount < 0 ? "-" : "";

			let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
			let j = (i.length > 3) ? i.length % 3 : 0;

			return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
		} catch (e) {
			console.log(e)
		}
	};

	formatValue = (value) => value.toFixed(0);

	render() {
		if (this.state.purchase) return (
			<div className="container" id="shop">
				<h1>Purchasing: {this.state.purchase.name} - {this.state.purchase.edition}</h1>
				<div className={"box"} id="purchaseformbox">
					<PurchaseForm cfdata={this.state.cfdata} onAuthUpdate={this.props.onAuthUpdate} stripe={this.props.stripe}
								  userAccount={this.props.userAccount}
								  product={this.state.purchase} quantity={this.state.quantity[this.state.purchase.slug]} />
				</div>
				<div className="purchaseFormRequired">
					<small style={{ fontSize: "14px" }}>* requried fields</small>
				</div>
			</div>
		)
		return (
			<div className="container" id="shop">
				<h1>Buy Blockchain Heroes Digital Trading Card Packs</h1>
				<h4 className={"subtitle"}>After you complete your purchase, you can open up the packs to reveal your cards!</h4>
				{
					this.state.loaded ?
						this.state.packs.map((pack, i) => {
							return (
								<div key={i} className="pack-wrapper">
									<h2>{pack.edition} <small>({(pack.price)} WAX)</small></h2>
									<div className="pack">
										{pack.supply <= 0 ? <div className={"sold-out-text"}>SOLD OUT</div> : null}
										<div className={"info" + (pack.supply <= 0 ? " sold-out" : "")}>
											{pack.supply > 0 ? <div className={"pack-counter"}>
												<AnimatedNumber
													value={pack.supply}
													formatValue={this.formatValue}
												/> remaining</div> : null}
											<div className={"pack-image-wrapper"}>
												<div className={"pack-image"}>
													<Pack src={"https://cloudflare-ipfs.com/ipfs/" + pack.image} />
												</div>
											</div>
											<div className="details">
												<div className="more-info">
													<div className="tag"><b className={"key"}>Pack:</b> {pack.edition}</div>
													<div className="tag"><b className={"key"}>Series:</b> {pack.name}</div>
													<div className="tag"><b className={"key"}>Release Date:</b> {pack.date}</div>
													<div className="tag"><b className={"key"}>Contents:</b> {pack.cardCount} Cards</div>
												</div>
												<p className="description">
													<b>Pack Includes:</b>
													{
														pack.content.map((item, i) => {
															return <li key={i}>{item}<br /></li>;
														})
													}
												</p>
											</div>
											{
												pack.supply > 0 ?
													<div className="form">
														<button className={"buy"}
																onClick={() => this.props.userAccount ? this.purchase(pack) : this.login()}>{this.props.userAccount ? "Buy now" : "Sign in "}</button>
														<Link to={"/catalog"}>
															<button className={"catalog gray"}>View Catalog</button>
														</Link>
													</div>
													: null
											}
										</div>
									</div>
								</div>
							);
						})
						:
						(window.innerWidth > 1600 ? [1, 2] : [1]).map(i => <div key={i} className="pack-wrapper"><Loader /></div>)
				}
			</div>
		);
	}
}


const InjectedShop = (prop) => (
	<ElementsConsumer>
		{({ stripe, elements }) => (
			<Shop stripe={stripe} elements={elements}  {...prop} />
		)}
	</ElementsConsumer>
);

const stripePromise = StripeHandler.getNewStripeInstance();
global.stripe = stripePromise;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const ShopRenderer = (prop) => {
	if (!stripePromise) return <Shop {...prop} />;
	return (
		<Elements stripe={stripePromise}>
			<InjectedShop {...prop} />
		</Elements>
	);
};


const mapStateToProps = state => {
	return {
		userAccount: state.userAccount
	}
}


const mapDispatchToProps = dispatch => {
	return {
		onAuthUpdate: userAccount => dispatch({ type: actionTypes.UPDATE_AUTH_USERACCOUNT, userAccount: userAccount })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopRenderer);