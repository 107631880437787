import React, { Component } from 'react'
import Config from '../../config/config';
import Reveal from '../../components/Inventory/Reveal'

import "./Unpack.css";
import UnpackVideoMega from './UnpackVideoMega'
import UnpackVideo from "./UnpackVideo";

import { Link, Redirect } from 'react-router-dom';

import * as API from "../../helpers/API";
import Loader from '../../components/Loader';

import { isSafari } from "react-device-detect";
import replaceIpfsHash from "../../helpers/ipfsDebugger";

export default class Unpack extends Component {

	state = {
		errorWhileUnboxing: false,
		showNewReveal: false,
		showOldReveal: false,
		loaded: false,
		cardsToDrop: [],
		droppedCards: [],
		currentCard: 0,
		hiddenVideo: false,
		iosEnabled: false,
		isMega: false,
		isTypeDetected: false,
		boxType: null
	}

	componentDidMount() {
		this.loadDrops();
	}

	loadDrops = (temp = []) => {
		const unboxingID = this.props.match.params.tx;

		this.setState({
			errorWhileUnboxing: false
		})

		API.createnfts(global.wax.userAccount, unboxingID).then(async res => {
			let { status } = res;
			const nfts = res.unboxedNfts;
			if (status === API.UNBOXING_STATUS.ALREADY_UNPACKED) {
				return window.location = '/inventory'
			}
			if (status === API.UNBOXING_STATUS.PENDING) {
				return setTimeout(() => this.loadDrops(temp), 800);
			}
			let cardsToDrop = [];

			for (let i = 0; i < nfts.length; i++) {

				let nft = nfts[i];

				if (!nft.data) nft.data = {};

				let id = nft.asset_id;
				let name = nft.name, img = nft.data.img;
				img = replaceIpfsHash(img);

				let resultCard = {
					name, img, id
				};

				cardsToDrop = [
					...cardsToDrop,
					resultCard
				]
			}

			const boxType = await API.getBoxType(global.wax.userAccount, unboxingID);

			console.log('boxtype', boxType);

			temp = [...temp, ...cardsToDrop];
			if (res.more) return this.loadDrops(temp);
			localStorage.setItem("inv_highlighted", temp.map(nft => nft.id).join(","));

			console.log('cardstodrop', temp);
		
			this.setState({
				loaded: true,
				cardsToDrop: temp,
				isMega: (cardsToDrop.length > 5),
				boxType
			}, () => {
				if (!isSafari || this.state.iosEnabled) {
					this.playVideo();
				}
			});
		}).catch(e => {
			console.error(e);
			this.setState({
				errorWhileUnboxing: true
			})
		})
	}

	newReveal = () => {
		return false;
	}

	_videoEnded = () => {
		if (this.newReveal()) {
			this.setState({
				showNewReveal: true
			})
		} else {
			this.setState({
				showOldReveal: true
			})
			this.showCard();
			setTimeout(() => {
				this.setState({ hiddenVideo: true })
			}, 1500);
			this.interval = setInterval(() => {
				this.showCard();
			}, 1000);
		}
	}

	videoEnded = () => {
		if (isSafari) {
			if (this.state.iosEnabled) {
				this._videoEnded()
			}
		} else {
			this._videoEnded()
		}
	}

	showCard = () => {
		let { currentCard, cardsToDrop, droppedCards } = this.state;
		if (currentCard >= cardsToDrop.length) return clearInterval(this.interval);
		droppedCards.push(cardsToDrop[currentCard].id);
		this.setState({ currentCard: currentCard + 1, cardsToDrop, droppedCards })
	}

	nextCard = () => {
		let { currentCard, droppedCards, cardsToDrop } = this.state;
		console.log('nextcard', currentCard);
		if (currentCard >= cardsToDrop.length) {
			droppedCards.pop()
			let redirect = undefined;
			if (droppedCards.length === 0) redirect = true;
			this.setState({ droppedCards, redirect })
		}
	}


	enableiOS = () => {
		this.refs.animationPlayer.play().then(() => {
			if (!this.state.loaded) {
				this.refs.animationPlayer.pause()
			}
		});
		this.setState({
			iosEnabled: true
		})
	}

	playVideo = () => {
		if (this.refs.animationPlayer)
			this.refs.animationPlayer.play();
	}

	render() {
		if (this.state.redirect) return <Redirect to={"/inventory?tab=cards"} />

		return (
			<div>
				{this.state.showNewReveal && <Reveal items={this.state.cardsToDrop} />}

				<div className="container" id="unpack">
					<div className={this.state.loaded ? "unboxing-dimmer unboxing-dimmer-done" : "unboxing-dimmer"}>
						<div className={"unboxing-dimmer-pack"}>
							<div>
								<div className={'unboxing-dimmer-loading'}>
									<div style={{ color: 'white' }}>
										Opening your pack
									</div>
									<div className="unpack-loader">Loading...</div>
								</div>
							</div>
						</div>
					</div>

					{!this.state.loaded ? <Loader /> : null}
					{
						this.state.showOldReveal &&

						<div className={"cardstodrop"}>
							{
								this.state.cardsToDrop.map((card, i) => <img draggable="false" key={i}
																			 onClick={() => this.nextCard()}
																			 className={this.state.droppedCards.includes(card.id) ? "dropped" : ""}
																			 id={card.id}
																			 src={"https://cloudflare-ipfs.com/ipfs/" + card.img}
																			 alt={card.name} />)
							}
							{
								this.state.currentCard > 0 && this.state.currentCard >= this.state.cardsToDrop.length ?
									<div className={"goto"}><Link to={"/inventory?tab=cards"}>
										<button>Go to Inventory</button>
									</Link></div>
									: null
							}
						</div>
					}
					{
						(this.state.loaded && (isSafari && !this.state.iosEnabled)) &&
						<div className={'safari-fix'} onClick={this.enableiOS}>
							<p>Click to reveal Cards</p>
						</div>
					}
					{
						(this.state.errorWhileUnboxing) &&
						<div className={'safari-fix'} onClick={() => {
							this.loadDrops([])
						}}>
							<p>Click to reveal Cards</p>
						</div>
					}
					<div className={"video-wrapper" + (this.state.hiddenVideo ? " hidden" : "")}>
						{
							this.state.boxType &&
							<video
								ref="animationPlayer"
								id="video"
								key={`ismega:${this.state.isMega}`}
								autoPlay={this.state.isMega} //this is needed otherwise it doesnt play when changing source
								playsInline
								onEnded={this.videoEnded}>
								{
									(() => {
										console.log("DECIDING ON ANIMATION: " + this.state.boxType);
										if (this.state.boxType === Config.packs.PACK_TYPES.five) {
											return <UnpackVideo key={Config.packs.PACK_TYPES.five} />
										} else if (this.state.boxType === Config.packs.PACK_TYPES.thirty) {
											return <UnpackVideoMega key={Config.packs.PACK_TYPES.thirty} />
										} else {
											return <UnpackVideo key={"placeholder"} />
										}
									})()
								}
								Your browser does not support the HTML5 player.
							</video>
						}
					</div>
				</div>
			</div>
		)
	}
}
