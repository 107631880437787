import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import config from '../../config/config';

export default class FollowingItem extends Component {
    render() {
        return (
            <Link to={"/p/" + (this.props.accountName ? this.props.accountName : this.props.name) + (this.props.isTrade? "/trade" : '')} className={"followingItem"}>
                <img src={config.media.LOGO} alt="" className="avatar" />
                <div>{ this.props.name ? this.props.name : this.props.accountName }</div>
                <div><small>{ this.props.name ? this.props.accountName : null }</small></div>
            </Link>
        )
    }
}
