import React, {Component} from 'react';
import * as actionTypes from "../../store/actionTypes";
import {connect} from "react-redux";

import waxlogo  from "../../assets/images/wax-logo-white.png";
import {Link, NavLink} from "react-router-dom";

import CartIcon from "../../assets/icons/cart.svg";
import CatalogIcon from "../../assets/icons/catalog.svg";
import InventoryIcon from "../../assets/icons/inventory.svg";
import TradeIcon from "../../assets/icons/trade.svg";
import CommunityIcon from "../../assets/icons/community.svg";
import ProfileIcon from "../../assets/icons/profile.svg";
import SignInIcon from "../../assets/icons/signin.svg";
import HomeIcon from "../../assets/icons/home.svg";
import FAQIcon from "../../assets/icons/faq.svg";
import config from '../../config/config';


class Navigation extends Component {

    login = async e => {
        e.preventDefault();
        let userAccount = await global.wax.login();
        if (userAccount) {
            this.props.onAuthUpdate(userAccount);
        }
        global.wax.whitelistedContracts = global.whitelistedContracts;
    }

    render() {
        return (
            <nav>
                <div className="top">
                    <Link to={"/"}>
                        <img src={config.media.LOGO} className="avatar" alt="" />
                    </Link>
                    <Link to={this.props.userAccount ? ("/p/" + this.props.userAccount) : ("/shop") }>
                        <div className="info">
                            <small>Your Account:</small>
                            <br />
                            <span className="name">{ this.props.userAccount ? this.props.userAccount : "Guest" }</span>
                        </div>
                    </Link>
                    <img src={waxlogo} className="header_gpk" alt="" />
                </div>
                <aside>
                        <div className="links">
                            <a href="https://bcheroes.com">
                                <img className={"icon"} src={HomeIcon} alt={""} />
                                <span className={"text"}>Home</span>
                            </a>
                            {/*<NavLink exact to={"/"} className={""}>*/}
                            {/*    <img className={"icon"} src={HomeIcon} alt={""} />*/}
                            {/*    <span className={"text"}>Home</span>*/}
                            {/*</NavLink>*/}
                            {/*<NavLink to={"/shop"}>*/}
                            {/*    <img className={"icon"} src={CartIcon} alt={""} />*/}
                            {/*    <span className={"text"}>Shop</span>*/}
                            {/*</NavLink>*/}
                            {
                                this.props.userAccount ?
                                    <NavLink to={"/inventory"}>
                                        <img className={"icon"} src={InventoryIcon} alt={""} />
                                        <span className={"text"}>My Inventory</span>
                                    </NavLink>
                                    : null
                            }
                            {/*{*/}
                            {/*    */}
                            {/*    !this.props.userAccount ?*/}
                            {/*        <NavLink to={"/catalog"}>*/}
                            {/*            <img className={"icon"} src={CatalogIcon} alt={""} />*/}
                            {/*            <span className={"text"}>Catalog</span>*/}
                            {/*        </NavLink>*/}
                            {/*    : */}
                            {/*    <NavLink to={"/catalog"} className={"catalog"}>*/}
                            {/*        <img className={"icon"} src={CatalogIcon} alt={""} />*/}
                            {/*        <span className={"text"}>Catalog</span>*/}
                            {/*    </NavLink>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    this.props.userAccount ?*/}
                            {/*        <NavLink to={"/trades"}>*/}
                            {/*            <img className={"icon"} src={TradeIcon} alt={""} />*/}
                            {/*            <span className={"text"}>Trade</span>*/}
                            {/*        </NavLink>*/}
                            {/*        : null*/}
                            {/*}*/}
                            {/*<NavLink to={"/community"}>*/}
                            {/*    <img className={"icon"} src={CommunityIcon} alt={""} />*/}
                            {/*    <span className={"text"}>Community</span>*/}
                            {/*</NavLink>*/}
                            <NavLink exact to={"/faq"} className={"catalog"}>
                                <img className={"icon"} src={FAQIcon} alt={""} />
                                <span className={"text"}>FAQ</span>
                            </NavLink>
                            <a href="https://bcheroes.com/category/news/">
                                <span className={"text"}>News</span>
                            </a>
                            {
                                this.props.userAccount ?
                                    <NavLink to={"/p/" + this.props.userAccount} className={"account"}>
                                        <img className={"icon"} src={ProfileIcon} alt={""} />
                                        <span className={"text"}>{ this.props.userAccount }</span>
                                    </NavLink>
                                    :
                                    <a href="/login" className={"signin"} onClick={this.login}>
                                        <img className={"icon"} src={SignInIcon} alt={""} />
                                        <span className={"text"}>Sign in</span>
                                    </a>
                            }
                        </div>
                </aside>
            </nav>
        );
    }
}

const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuthUpdate: userAccount => dispatch({type: actionTypes.UPDATE_AUTH_USERACCOUNT, userAccount: userAccount})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);