export const Browsers = {
    OPERA: "Opera",
    INTERNET_EXPLORER: "Internet Explorer",
    CHROME: "Chrome",
    SAFARI: "Safari",
    FIREFOX: "Firefox",
};

export const OS = {
    WINDOWS: "Windows",
    MAC: "MacOS",
    UNIX: "UNIX",
    LINUX: "Linux",
    ANDROID: "Android",
    IOS: "iOS",
    UNKOWN: "Unknown"
};

export const getDeviceInformation = () => {
    // OS Information
    let OSName=OS.UNKOWN;
    if (navigator.appVersion.indexOf("Win")!==-1) OSName=OS.WINDOWS;
    if (navigator.appVersion.indexOf("Mac")!==-1) OSName=OS.MAC;
    if (navigator.appVersion.indexOf("X11")!==-1) OSName=OS.UNIX;
    if (navigator.appVersion.indexOf("Linux")!==-1) OSName=OS.LINUX;
    if (navigator.appVersion.indexOf("Android")!==-1) OSName=OS.ANDROID;
    if (navigator.appVersion.indexOf("iPhone")!==-1) OSName=OS.IOS;
    if (navigator.appVersion.indexOf("iPad")!==-1) OSName=OS.IOS;

    // Browser Information
    // let nVer = navigator.appVersion;
let nAgt = navigator.userAgent;
let browserName  = navigator.appName;
let fullVersion  = ''+parseFloat(navigator.appVersion);
let majorVersion = parseInt(navigator.appVersion,10);
let nameOffset,verOffset,ix;

// In Opera, the true version is after "Opera" or after "Version"
if ((verOffset=nAgt.indexOf("Opera"))!==-1) {
 browserName = Browsers.OPERA;
 fullVersion = nAgt.substring(verOffset+6);
 if ((verOffset=nAgt.indexOf("Version"))!==-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset=nAgt.indexOf("MSIE"))!==-1) {
 browserName = Browsers.INTERNET_EXPLORER;
 fullVersion = nAgt.substring(verOffset+5);
}
// In Chrome, the true version is after "Chrome" 
else if ((verOffset=nAgt.indexOf("Chrome"))!==-1) {
 browserName = Browsers.CHROME;
 fullVersion = nAgt.substring(verOffset+7);
}
// In Safari, the true version is after "Safari" or after "Version" 
else if ((verOffset=nAgt.indexOf("Safari"))!==-1) {
 browserName = Browsers.SAFARI;
 fullVersion = nAgt.substring(verOffset+7);
 if ((verOffset=nAgt.indexOf("Version"))!==-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In Firefox, the true version is after "Firefox" 
else if ((verOffset=nAgt.indexOf("Firefox"))!==-1) {
 browserName = Browsers.FIREFOX;
 fullVersion = nAgt.substring(verOffset+8);
}
// In most other browsers, "name/version" is at the end of userAgent 
else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
          (verOffset=nAgt.lastIndexOf('/')) ) 
{
 browserName = nAgt.substring(nameOffset,verOffset);
 fullVersion = nAgt.substring(verOffset+1);
 if (browserName.toLowerCase()===browserName.toUpperCase()) {
  browserName = navigator.appName;
 }
}
// trim the fullVersion string at semicolon/space if present
if ((ix=fullVersion.indexOf(";"))!==-1)
   fullVersion=fullVersion.substring(0,ix);
if ((ix=fullVersion.indexOf(" "))!==-1)
   fullVersion=fullVersion.substring(0,ix);

majorVersion = parseInt(''+fullVersion,10);
if (isNaN(majorVersion)) {
 fullVersion  = ''+parseFloat(navigator.appVersion); 
 majorVersion = parseInt(navigator.appVersion,10);
}
    return {
        browser: browserName,
        full_version:fullVersion,
        major_version: majorVersion,
        os: OSName,
    };
}