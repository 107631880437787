/**
 *
 * Import Media, Classes, etc.
 *
 */

import Logo from "../assets/images/icon.png";
import Brand from "../assets/images/icon.png";
import WAX from "../assets/images/wax-logo-white.png";
import BigBrand from "../assets/images/logo.png";
import EnvHandler from "../helpers/EnvHandler";

/**
 *
 * ENUMS
 *
 */

 console.log("env", process.env)

const NFT_STANDARDS = {
	SIMPLE_ASSETS: "sa",
	ATOMICASSETS: "aa",
}

const templates = {
	// 
	hero: EnvHandler.isDevMode() ? 4824 : 5233,
	titan: EnvHandler.isDevMode() ? 4825 : 5234
}

console.log("templates", templates);

// const PAGES = {
//     LANDING_PAGE: true,
//     INVENTORY: true,
//     CATALOG: true,
//     TRADING_INTERFACE: true,
//     COMMUNITY_TAB: true,
//     PROFILE_PAGE: true,
//     FAQ: true
// }

/**
 *
 * CONTRACTS
 *
 */

const style = {
	color: "#323232",
}


// TODO UPDATE
const contracts = {
	UNPACKING: EnvHandler.isDevMode() ? "4tioi.waa" : "unbox.heroes"
}
console.log("contracts", contracts);


/**
 *
 * Tracking Activity
 *
 */

const contracts_to_track = {
	[contracts.UNPACKING]: ["unbox"],
};

/**
 *
 * PACK CONFIG
 *
 */

const packs = {
	PACK_TYPES: {
		five: 'five',
		thirty: 'thirty',
	},
	TOKEN_NAMES: {
		five: "WSFIVE",
		thirty: "WSMEGA"
	},
	PACK_TEMPLATE_IDS: [
		templates.hero,
		templates.titan,
	],
	PACK_NAMES: {
		[templates.hero]: 'five',
		[templates.titan]: 'thirty'
	}
}

console.log("packs", packs);

/**
 *
 * SETTINGS
 *
 */

const settings = {
	NFT_STANDARD: NFT_STANDARDS.ATOMICASSETS,
	CARD_EFFECT: true,
	HAS_PACKS: true
}

/**
 *
 * MEDIA FILES
 *
 */

const media = {
	DOMAIN: "bcheroes.com",
	LOGO: Logo,
	BRAND: Brand,
	WAX: WAX,
	BIG_LOGO: BigBrand,
}

/**
 *
 * SOCIAL Copy
 *
 */

const social = {
	SELF_SHARE_TEXT: ``,
	SHARE_TEXT: ``,
}

/**
 *
 * Merge & Export
 *
 */

const ipfsGateway = 'https://cloudflare-ipfs.com/ipfs/';

const ipfsFiles = 'QmS6U7d269tQqV3HRGhbm4YFiKFCbn6FyLAYUZ2otFQEDi';

const COLLECTION = EnvHandler.isDevMode() ? "4tioiwaaaa13" : "officialhero";

const UNLOCK_TIME_MS = 1596906000000;

console.log("COLLECTION", COLLECTION);
const config = {
	contracts,
	contracts_to_track,
	packs,
	media,
	settings,
	social,
	style,
	templates,
	unlockTime: UNLOCK_TIME_MS,
	stripeEnabled: false,
	collection_name: COLLECTION,
	cardCollectionName: COLLECTION,
	packCollectionName: COLLECTION,
	cardSchema: ["series1", "bonusheroes", "founderclass", "presaledeck"],
	packSchema: ["packs"],
	issueContract: EnvHandler.isDevMode() ? "dk2aubuy1111" : "buy.heroes",
	getIpfsUrl(path) {
		return `${ipfsGateway}${path}`;
	},
	ipfsFiles,
	fileTypes: {
		'common': { back: 'jpg', front: 'jpg' },
		'uncommon': { back: 'jpg', front: 'jpg' },
		'rare': { back: 'jpg', front: 'jpg' },
		'epic': { back: 'jpg', front: 'gif' },
		'legendary': { back: 'jpg', front: 'gif' },
		'mythic': { back: 'jpg', front: 'gif' },
		'secret': { back: 'jpg', front: 'gif' },
		'sticker': { back: 'jpg', front: 'png' },
		'class': { back: 'jpg', front: 'png' }
	},
	isPortrait: (cardid) => {
		return ([].includes(cardid));
	}
};

console.log("config", config);

export default config;