import React, { Component } from 'react'
import replaceIpfsHash from "../../helpers/ipfsDebugger";

import * as VanillaTilt from 'vanilla-tilt';

import './Card.css';
import { isAndroid, isChrome, isChromium, isIOS } from "react-device-detect";
import config from '../../config/config';

class Card extends Component {

	_mounted = false;
	sizerElement = React.createRef();

	constructor(props) {
		super(props);
		let back = "";
		if (props.back) back = replaceIpfsHash(props.back);
		if (props.backimg) back = replaceIpfsHash(props.backimg);
		if (back === "") console.error("Card should have a back image, but it doesnt display for some reason")
		else if (!back.includes("http")) back = "https://cloudflare-ipfs.com/ipfs/" + back;
		this.state = {
			showBackside: this.props.startBackside || false,
			back: back,

			width: props.width ? props.width : 0,
			height: props.height ? props.height : 0,
			forcedSizes: props.width !== null || props.height !== null,

			flipBackClass: this.props.startBackside ? 'tilt3-flip-back' : '',
			portrait: this.props.portrait,
		}
	}

	canFlip = () => {
		if (this.getIsSticker())
			return false;
		if (this.props.noFlip)
			return false;
		if (!this.props.onFlip)
			return true;
		return this.props.onFlip(this.state.showBackside);
	}
	iosToggle = (e) => {
		console.log('eee', e);
		if (this.canFlip()) {
			this.setState({
				showBackside: !this.state.showBackside
			});
		}
	}

	toggle() {
		if (this.canFlip()) {
			this.setState({
				showBackside: !this.state.showBackside,
				flipBackClass: "tilt3-flip-back"
			});
		}
	}

	componentDidMount() {
		this._mounted = true;
		this.initTilt();
		this.resizeListener = window.addEventListener("resize", () => {
			if (this._mounted) {
				this.updateSizes();
			}
		})
	}

	componentWillUnmount() {
		this._mounted = false;
		if (this.resizeListener) {
			window.removeEventListener("resize", this.resizeListener)
			delete this.resizeListener;
		}
	}

	componentDidUpdate() {
		this.updateSizes();
	}

	initTilt_(node) {
		if (node && node.vanillaTilt)
			node.vanillaTilt.destroy();
		VanillaTilt.init(node, {
			max: 25,
			gyroscope: true,
			startY: 9,

			perspective: 1400,
			easing: "cubic-bezier(.03,.98,.52,.99)",
			speed: (isChrome || isChromium) ? 1200 : 0,
			glare: true,
			maxGlare: 0.05,
			scale: 1.07,
			reset: true
		});
	}

	initTilt() {
		this.initTilt_(this.rootNode);
		this.initTilt_(this.rootNodeBack);
	}

	updateSizes = () => {
		let width, height;
		if (this.sizerElement && this.sizerElement.current) {
			height = this.sizerElement.current.height;
			width = this.sizerElement.current.width;
		}
		if (
			this.sizerElement &&
			this.sizerElement.current &&
			(this.state.width !== width || this.state.height !== height)) {

			this.setState({
				width,
				height
			})
		}
	}

	getIsSticker = () => {
		return this.props.src.indexOf('/sticker/') >= 0;
	}

	getIsClass = () => {
		return this.props.src.indexOf('/class/') >= 0;
	}

	getMaskStyle = () => {
		if (
			this.props.src.indexOf('/common/') >= 0 ||
			this.props.src.indexOf('rare') >= 0 ||
			this.props.src.indexOf('epic') >= 0
		) {
			return {
				MaskImage: `url(${config.getIpfsUrl(config.ipfsFiles + '/masks/cre/' + this.props.cardid + '.png')})`,
				WebkitMaskImage: `url(${config.getIpfsUrl(config.ipfsFiles + '/masks/cre/' + this.props.cardid + '.png')})`
			}
		} else {
			return {
				MaskImage: `url(${config.getIpfsUrl(config.ipfsFiles + '/masks/ulmg/' + this.props.cardid + '.png')})`,
				WebkitMaskImage: `url(${config.getIpfsUrl(config.ipfsFiles + '/masks/ulmg/' + this.props.cardid + '.png')})`
			}
		}
	}

	render() {
		const isSpecial = this.props.src.indexOf('special') >= 0;
		const id = this.props.id;

		if (isIOS || isAndroid || !config.settings.CARD_EFFECT || this.props.disable3d) {
			return (
				<div onMouseEnter={this.props.onMouseEnter}
					 className={'card-ios-container ' + (this.state.portrait ? 'portrait' : '')}
					 onClick={(e) => {
						 this.iosToggle(e)
					 }}>
					<img src={this.props.src}
						 draggable="false"
						 alt=""
						 className={this.state.showBackside ? 'card-ios-hidden' : ''}
						 style={{ maxWidth: '100%' }}
					/>
					<img src={this.state.back}
						 alt=""
						 draggable="false"
						 className={!this.state.showBackside ? 'card-ios-back card-ios-hidden' : 'card-ios-back'}
					/>
					{
						(isSpecial && this.state.showBackside) &&
						<div className={'mobile-plastic-id'}>
							{id}
						</div>
					}
				</div>
			)
		}

		return (
			<div
				className={"wtf-safari tilt3-wrapper " + (this.state.portrait ? ' portrait ' : '') + (this.state.showBackside ? "tilt3-show-backside" : this.state.flipBackClass)}
				onClick={() => {
					this.toggle()
				}} style={{ cursor: "pointer" }}>
				<img src={this.props.src}
					 alt=""
					 style={{ maxWidth: '100%' }}
					 ref={this.sizerElement}
					 onLoad={() => this.updateSizes()}
					 className="tilt3-img-sizer"
				/>
				<div className="tilt3-front" style={{ width: `${this.state.width}px`, height: `${this.state.height}px` }}>
					<div className="tilt3-rotator" style={{ backgroundImage: `url(${this.props.src})` }}
						 ref={node => (this.rootNode = node)}>

						{
							!this.getIsSticker() && !this.getIsClass() && <div>
								<div
									className={"tilt3-inner tilt3-card-inner-1 tilt3-shadow"}
									style={this.getMaskStyle()}
								/>
								<div
									className={"tilt3-inner tilt3-card-inner-1"}
									style={{
										backgroundImage: `url(${this.props.src})`,
										...this.getMaskStyle()
									}}
								/>
							</div>
						}

					</div>
				</div>
				<div className="tilt3-back"
					 style={{
						 width: `${!this.state.portrait ? this.state.height : this.state.width}px`,
						 height: `${!this.state.portrait ? this.state.width : this.state.height}px`
					 }}>
					<div className="tilt3-rotator" ref={node => (this.rootNodeBack = node)}>
						<div className="tilt3-back-inner"
							 style={{
								 width: `${this.state.height}px`,
								 height: `${this.state.width}px`,
								 backgroundImage: this.getIsSticker() ? `url(${this.props.src})` : `url(${this.state.back})`
							 }}>
						</div>
						<div className="tilt3-inner tilt3-card-shadow" />
					</div>
				</div>
			</div>
		)
	}

}

export default Card;
