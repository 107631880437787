import React, { Component } from 'react';
import AuxLayout from "../../helpers/AuxLayout";

import CloseIcon from '../../assets/icons/close.svg';
import ChainIcon from '../../assets/icons/chain.svg';
import ShareIcon from '../../assets/icons/share.svg';
import FacebookIcon from '../../assets/icons/facebook.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import RedditIcon from '../../assets/icons/reddit.svg';

import WithdrawIcon from '../../assets/icons/withdraw.svg';

import Card from '../Card/Card';
import config from '../../config/config';
import './Inspect.css';
import replaceIpfsHash from "../../helpers/ipfsDebugger";

import mythMarketIcon from '../../assets/communitymarkets/shatner.market.png';
import waxplorerIcon from '../../assets/communitymarkets/waxplorer.jpg';

class Inspect extends Component {

	state = {
		flipped: false,
		sharing: false,

		crafting: false,
		showDimmer: false,
		result: null,
		mobileCallback: null,
		preload: null
	}

	communityMarkets = [
		// {
		// 	search: 'https://heroes.market',
		// 	icon: mythMarketIcon,
		// 	name: "heroes.market"
		// },
		{
			search: 'https://www.waxplorer.com/search',
			icon: waxplorerIcon,
			name: "WAXplorer"
		},
	]

	close = () => {
		if (this.state.crafting && !this.state.result)
			return;

		this.props.close(this.state.crafting);
		this.setState({
			flipped: false,
			sharing: false,

			crafting: false,
			showDimmer: false,
			result: null
		})
	}

	getContainerStyle = () => {
		if (!config.isPortrait(this.props.item.cardid)) {
			return {
				height: "490px",
				width: "350px",
				marginBottom: "25px"
			}
		} else {
			return {
				height: "280px",
				width: "400px",
				marginBottom: "25px"
			}
		}
	}

	render() {
		let own = global.wax && (global.wax.userAccount === this.props.owner || global.wax.userAccount === this.props.trade_owner);
		let notExistant = this.props.specialFilter === "missing";
		if (!this.props.active) return null;
		let img = "";
		let backimg = "";
		if (this.props.back) backimg = this.props.back;
		if (this.props.data && this.props.data.backimg) backimg = this.props.data.backimg;
		if (this.props.backimg) backimg = this.props.data.backimg;
		img = this.props.data.img;
		backimg = replaceIpfsHash(backimg)
		img = replaceIpfsHash(img)
		if (this.props.type === "card") return (
			<AuxLayout>
				<div className={"dimmer " + (this.props.active ? "active" : "")} onClick={this.close} />
				<div className={"InspectCard " + (this.props.active ? "active" : "")} style={this.props.style}>
					<div style={this.getContainerStyle()}>
						<Card
							id={this.props.id}
							src={img}
							cardid={this.props.data.cardid}
							back={backimg}
						/>
					</div>
					<div>
						<center><small style={{ color: "#fefefe" }}>Click card to flip</small></center>
					</div>
					<div className={"actions"}>
						{
							!notExistant &&
							<div className={"action-item share"}
								 tooltip="Showcase Cards"
								 tooltip-position="bottom"
								 onClick={() => this.setState({ sharing: !this.state.sharing })}>
								<img src={ShareIcon} alt={"Showcase Cards"} />
							</div>
						}
						{
							!notExistant &&
							<div className={"action-item chain"}
								 tooltip="View on the WAX Blockchain"
								 tooltip-position="bottom"
								 onClick={() => window.open(`https://wax.bloks.io/nft/simpleassets/${this.props.owner}/sassets/${this.props.id}`, '_blank')}>
								<img src={ChainIcon} alt={"View on the WAX Blockchain"} />
							</div>
						}
						{
							!notExistant && this.props.in_wet && own &&
							<div className={"action-item withdraw"}
								 tooltip="Withdraw from Trading"
								 tooltip-position="bottom"
								 onClick={this.props.withdraw}>
								<img src={WithdrawIcon} alt={"Withdraw from Trading"} />
							</div>
						}
						<div className={"action-item close"} tooltip="Close" tooltip-position="top"
							 onClick={this.close}>
							<img src={CloseIcon} alt={"Close Inspect"} />
						</div>
						{
							this.state.sharing ?
								<AuxLayout>
									<hr />
									<a target="_blank"
									   rel="noopener noreferrer"
									   href={`https://twitter.com/intent/tweet?url=https://bcheroes.com/p/${global.wax.userAccount}&text=${encodeURIComponent(own ? config.social.SELF_SHARE_TEXT : config.social.SHARE_TEXT)}&via=BCHeroes&related=BCHeroes,Blockchain,Heroes,WAX,WAX_io`}
									   className={"share"} tooltip="Twitter" tooltip-position="top">
										<img src={TwitterIcon} alt={"Twitter"} />
									</a>
									<a target="_blank"
									   rel="noopener noreferrer"
									   href={`https://www.facebook.com/sharer/sharer.php?u=https://bcheroes.com/p/${global.wax.userAccount}`}
									   className={"share"} tooltip="Facebook" tooltip-position="top">
										<img src={FacebookIcon} alt={"Facebook"} />
									</a>
									<a target="_blank"
									   rel="noopener noreferrer"
									   href={`https://www.reddit.com/submit?url=https://bcheroes.com/p/${global.wax.userAccount}&title=${encodeURIComponent(own ? config.social.SELF_SHARE_TEXT : config.social.SHARE_TEXT)}`}
									   className={"share"} tooltip="Reddit" tooltip-position="top">
										<img src={RedditIcon} alt={"Reddit"} />
									</a>
								</AuxLayout>
								: null
						}
					</div>
				</div>
			</ AuxLayout>
		);
		return null
	}
}

export default Inspect;