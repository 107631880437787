import React, { Component } from 'react'

export default class FilterBar extends Component {

	lastState = null;

	state = {
		rarity: "",
		search: "",
		sort: "id",
		special_filter: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			...props.defaultState
		}
	}

	componentDidUpdate() {
		if (this.lastState !== this.state) {
			this.lastState = this.state;
			if (this.props.onChange) this.props.onChange(this.state)
		}
	}

	searchInput = e => {
		this.setState({ search: e.target.value })
	}

	rarityChange = e => {
		this.setState({ rarity: e.target.value })
	}

	specialFilterChange = e => {
		this.setState({ special_filter: e.target.value })
	}

	sortChange = e => {
		this.setState({ sort: e.target.value });
	}

	render() {
		return (
			<div className="box" id="FilterBar">
				<input type="search" onKeyDown={this.searchInput} onInput={this.searchInput} placeholder="Search..." />
				{
					!this.props.noSpecial ?
						<select name="special_filter" id="special_filter" defaultValue={this.state.special_filter}
							onChange={this.specialFilterChange}>
							<option value={""}>Show All</option>
							<option value={"missing"}>Missing</option>
							<option value={"duplicates"}>Duplicates</option>
						</select>
					: null
				}

				<select name="rarity" id="rarity" defaultValue={this.state.rarity} onChange={this.rarityChange}>
					<option value={""}>All Rarities</option>
					<option value={"common"}>Common</option>
					<option value={"uncommon"}>Uncommon</option>
					<option value={"rare"}>Rare</option>
					<option value={"epic"}>Epic</option>
					<option value={"legendary"}>Legendary</option>
					<option value={"mythic"}>Mythic</option>
					<option value={"secret"}>Secret Smoke</option>
					<option value={"class"}>Class</option>
					<option value={"sticker"}>Sticker</option>
				</select>
				{
					this.props.showSort && false &&
					<select name="rarity" id="sort" defaultValue={this.state.sort} onChange={this.sortChange}>
						<option value={"id"}>Sort by creation</option>
						<option value={"cardid"}>Sort by ID</option>
						<option value={"mint"}>Sort by Mint</option>
					</select>
				}
			</div>
		)
	}
}
