import React, {Component} from 'react';
import AuxLayout from "../../helpers/AuxLayout";

class Popup extends Component {
    render() {
        return (
            <AuxLayout>
                <div className={"dimmer " + (this.props.active ? "active" : "")} onClick={this.props.dimmerNotClickable ? null : this.props.close} />
                <div className={"popup " + (this.props.active ? "active" : "")} style={{
                    ...(this.props.style ? this.props.style : {}),
                    boxShadow: "0 2px 4px rgba(0, 0, 0, .3)"
                }}>
                    {  this.props.children }
                </div>
            </ AuxLayout >
        );
    }
}

export default Popup;