import React, { Component } from 'react'

export default class PackFilterBar extends Component {

	lastState = null
	state = {
		series: "",
		type: "",
		search: "",
	};

	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			...props.defaultState
		}
	}

	componentDidUpdate() {
		if (this.lastState !== this.state) {
			this.lastState = this.state;
			if (this.props.onChange) this.props.onChange(this.state)
		}
	}

	searchInput = e => {
		this.setState({ search: e.target.value })
	}

	typeChange = e => {
		this.setState({ type: e.target.value })
	}
	specialFilterChange = e => {
		this.setState({ special_filter: e.target.value })
	}
	seriesChange = e => {
		this.setState({ series: e.target.value })
	}

	render() {
		return (
			<div className="box" id="FilterBar">
				<input type="search" onKeyDown={this.searchInput} onInput={this.searchInput} placeholder="Search..." />
				<select name="special_filter" className="longer" defaultValue={this.state.special_filter} id="special_filter"
						onChange={this.specialFilterChange}>
					<option value={""}>Show All</option>
					<option value={"trade"}>In Trade</option>
				</select>
			</div>
		)
	}
}
